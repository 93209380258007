import React from 'react';
import { createContainer } from 'react-tracked';

const useValueDarkMode = () => React.useState(JSON.parse(localStorage.getItem("darkMode")) || false);

export const { Provider: DarkModeState, useTracked: useTrackedDarkMode } = createContainer(useValueDarkMode, { concurrentMode: true });

const useValueUser = () => React.useState({});

export const { Provider: UserState, useTracked: useTrackedUser } = createContainer(useValueUser, { concurrentMode: true });

const useValueLocation = () => React.useState(undefined);

export const { Provider: LocationState, useTracked: useTrackedLocation } = createContainer(useValueLocation, { concurrentMode: true });

const useValueAllLocations = () => React.useState(undefined);

export const { Provider: AllLocationState, useTracked: useTrackedAllLocations } = createContainer(useValueAllLocations, { concurrentMode: true });

const useValueCustomer = () => React.useState(undefined);

export const { Provider: CustomerState, useTracked: useTrackedCustomer } = createContainer(useValueCustomer, { concurrentMode: true });

const useValueRecentOrders = () => React.useState(JSON.parse(localStorage.getItem("recentOrders") || '[]') || []);

export const { Provider: RecentOrdersState, useTracked: useTrackedRecentOrders } = createContainer(useValueRecentOrders, { concurrentMode: true });

const MAX_RECENT_ORDERS = 50
export const add_recent_order = (order, recentOrders, setRecentOrders) => {
    const recentOrderIndex = recentOrders.findIndex((x) => x.id === order.id)
    let new_recentOrders = [...recentOrders]
    if (recentOrderIndex !== -1) {
        new_recentOrders.splice(recentOrderIndex, 1) // Move new item to front of list
        new_recentOrders = [order].concat(new_recentOrders)
    } else {
        new_recentOrders = [order].concat([...recentOrders]).slice(0,MAX_RECENT_ORDERS)
    }
    setRecentOrders(new_recentOrders)
    localStorage.setItem("recentOrders", JSON.stringify(new_recentOrders))
}