import * as React from "react"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    DataGrid,
} from "@mui/x-data-grid"
import { AXIOS_API_INSTANCE, LONG_DATE_FORMAT } from "../Constants"

import { send_authorized_request } from "../Constants/cognito"
import dayjs from "dayjs"
import { useCookies } from "react-cookie"
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Divider, InputAdornment, TextField } from "@mui/material"
import { formatPhoneNumber, moneyFormat } from "../Utils";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import OrderView from "./OrderView";
import { useSnackbar } from "notistack";
import SearchCustomerModal from "./SearchCustomerModal";
import { useTrackedAllLocations, useTrackedLocation, useTrackedRecentOrders } from "./UserState";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
dayjs.extend(timezone);
dayjs.extend(utc);


const columns = (locations) => [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
        editable: false,
        sortable: false,
        filterable: false
    },
    {
        field: "customer",
        headerName: "Customer",
        width: 150,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
            return (
                <Box>
                    <span>{params.value?.cust_name ?? params.row.customer_cached_name}</span><br></br>
                    <span>{formatPhoneNumber(params.value?.cust_phone ?? params.row.customer_cached_phone)}</span>
                </Box>
            )
        }
    },
    {
        field: "paid",
        headerName: "Status",
        width: 200,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
            if (params.row.paid) {
                return (<Chip icon={<CheckCircleIcon />} label="Paid" color={'green'} />)
            } else if (params.row.payment_in_progress) {
                return (<Chip icon={<HourglassBottomIcon />} label="Payment In Progress" color={'orange'} />)
            } else if (params.row.refunded) {
                return (<Chip icon={<CurrencyExchangeIcon />} label="Refunded" color={'orange'} />)
            } else if (params.row.canceled) {
                return (<Chip icon={<CancelIcon />} label="Canceled" color={'grey'} />)
            } else {
                return (<Chip icon={<MoneyOffCsredIcon />} label="Payment Due" color={'error'} />)
            }
        }
    },
    {
        field: "net_total",
        headerName: "Total",
        width: 100,
        editable: false,
        sortable: false,
        filterable: false,
        valueGetter: (params) => {
            return `$${moneyFormat(params.value)}`
        }
    },
    {
        field: "create_date",
        headerName: "Date Created",
        width: 200,
        editable: false,
        sortable: false,
        filterable: false,
        valueGetter: (params) => {
            return dayjs.unix(params.value).local().format(LONG_DATE_FORMAT)
        }
    },
    {
        field: "location_id",
        headerName: "Location",
        width: 200,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
            return (<span>{locations.find((x) => x.id === params.value).short_name}</span>)
        }
    },
]

export default function Orders({ viewRecentOrders }) {
    const { enqueueSnackbar } = useSnackbar();
    const [cookies, setCookie] = useCookies(["pagination"])
    const [recentOrders,] = useTrackedRecentOrders()
    const [selectedLocation,] = useTrackedLocation()
    const [locations,] = useTrackedAllLocations()
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: cookies.pagination?.orders_pageSize || 25,
        page: 0
    });
    const [rowCount, setRowCount] = React.useState(0)
    const [LastEvaluatedKeyMap, SetLastEvaluatedKeyMap] = React.useState({})
    const [rowData, setRowData] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [searchDate, setSearchDate] = React.useState(dayjs())
    const [orderSearch, setOrderSearch] = React.useState('')
    const [, setCustomerOrderSearch] = React.useState(undefined)
    const [ignoreDate, setIgnoreDate] = React.useState(false)
    // const [includePaid, setIncludePaid] = React.useState(false)
    const [openCustomerSearchModal, setOpenCustomerSearchModal] = React.useState(false)
    const navigate = useNavigate();

    const { orderId } = useParams();

    const orderRowClick = (params) => {
        navigate(`${params.row.id}`)
    }

    const setMyPaginationModel = (model) => {
        if (!isLoading) {
            setCookie(
                "pagination",
                { ...cookies.pagination, orders_pageSize: model.pageSize },
                { path: "/" }
            )
            setPaginationModel(model)
        }
    }

    const searchById = () => {
        setIgnoreDate(true)
        setPaginationModel({ ...paginationModel, page: 0 })
        setCustomerOrderSearch(undefined);
        getOrders(orderSearch, true)
    }

    const searchByCustomer = (result) => {
        setIgnoreDate(true)
        setPaginationModel({ ...paginationModel, page: 0 })
        setCustomerOrderSearch(result)
        getOrders(result.id, false, true)
    }

    const searchByDate = () => {
        setIgnoreDate(false)
        setCustomerOrderSearch(undefined);
        setPaginationModel({ ...paginationModel, page: 0 })
    }

    const updateOrderSearch = (event) => {
        const text = event.target.value.trim()
        const found_short = text.match(/^[\d]{0,4}$/);
        if (found_short) {
            setOrderSearch(text)
        }
        const five_digit = text.match(/^[\d]{5}$/);
        if (five_digit) {
            setOrderSearch(text.slice(0, 4) + "-" + text.slice(4))
        }
        const found_dash = text.match(/^[\d]{0,4}-[\d]{0,4}$/);
        if (found_dash) {
            setOrderSearch(text)
        }
    }

    const getOrders = (orderSearch, is_short_id, is_cust_id) => {
        setIsLoading(true)
        let my_filters = {};
        if (is_short_id) {
            my_filters['short_id'] = orderSearch
        } else if (is_cust_id) {
            my_filters['customer_id'] = orderSearch
        } else {
            my_filters['create_date_month_year'] = searchDate.format('YYYYMM')
            my_filters['location_id'] = selectedLocation.id
            if (LastEvaluatedKeyMap[paginationModel.page - 1]) {
                my_filters['LastEvaluatedKey'] = LastEvaluatedKeyMap[paginationModel.page - 1]
            }
        }
        send_authorized_request(() =>
            AXIOS_API_INSTANCE.get("orders", {
                params: {
                    search: my_filters,
                    page_size: paginationModel.pageSize
                    // include_paid: includePaid,
                },
            })
                .then(
                    (orders) => {
                        console.log(orders)
                        setRowData(orders.data.data.sort((a, b) => parseInt(b.create_date) - parseInt(a.create_date)))
                        if (orders.data.LastEvaluatedKey) {
                            setRowCount(Number.MAX_VALUE)
                            SetLastEvaluatedKeyMap({ ...LastEvaluatedKeyMap, [paginationModel.page]: orders.data.LastEvaluatedKey })
                        } else {
                            if (is_short_id || is_cust_id) {
                                setRowCount(orders.data.data.length)
                            } else {
                                setRowCount(orders.data.data.length + (paginationModel.page * paginationModel.pageSize))
                            }
                        }
                        setIsLoading(false)
                    }
                ).catch((error) => {
                    setIsLoading(false)
                    console.log(error);
                    enqueueSnackbar(`Failed to load orders: ${error.response?.data?.message ?? "Unknown Error Occurred"}`, { variant: 'error' })
                }))
    }


    React.useEffect(() => {
        if (orderId === undefined && !viewRecentOrders && !ignoreDate) {
            getOrders('', false, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [/*includePaid,*/ orderId, paginationModel])

    React.useEffect(() => {
        if (orderId === undefined && !viewRecentOrders && !ignoreDate) {
            SetLastEvaluatedKeyMap({})
            setPaginationModel({ ...paginationModel, page: 0 })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocation])

    return (
        <React.Fragment>
            {orderId === undefined &&
                <Grid container space={1}>
                    <Grid item xs>
                        {!viewRecentOrders &&
                            // <Grid container columnSpacing={1} sx={{ mr: 1, ml: 1, mt: 0.5, mb: 0.5 }} direction="row" justifyContent="flex-start" alignItems="center">
                            //     <Grid item xs="auto">
                            <Grid container columnSpacing={1} justifyContent="flex-start" alignItems="center">
                                <Grid item xs="auto">
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="ordersearch"
                                        label="Search Order Short ID"
                                        type="text"
                                        variant="filled"
                                        disabled={isLoading}
                                        value={orderSearch}
                                        onChange={updateOrderSearch}
                                        placeholder="####-####"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {<SearchIcon />}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs="auto">
                                    <Button disabled={isLoading || orderSearch.length !== 9} color={"primary"} variant={"contained"} onClick={searchById}>Search ID</Button>
                                </Grid>
                                <Divider sx={{ ml: 1 }} orientation="vertical" flexItem />
                                <Grid item xs="auto">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={'Month Lookup'}
                                            views={['month', 'year']}
                                            value={searchDate}
                                            onChange={(newValue) => setSearchDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button disabled={isLoading} color={"primary"} variant={"contained"} onClick={searchByDate}>Search Month</Button>
                                </Grid>
                                <Divider sx={{ ml: 1 }} orientation="vertical" flexItem />
                                <Grid item xs="auto">
                                    <Button disabled={isLoading} color={"primary"} variant={"contained"} onClick={() => setOpenCustomerSearchModal(true)}>Search Customer</Button>
                                </Grid>
                            </Grid>
                            //     </Grid>
                            // </Grid>
                        }
                        <DataGrid
                            density="compact"
                            rows={viewRecentOrders ? [...recentOrders] : rowData}
                            rowCount={viewRecentOrders ? recentOrders.length : rowCount}
                            columns={columns(locations)}
                            disableRowSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
                            autoHeight={true}
                            loading={isLoading}
                            pageSizeOptions={[25]}
                            pagination
                            paginationMode={ignoreDate || viewRecentOrders ? "client" : "server"}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setMyPaginationModel}
                            hideFooterSelectedRowCount
                            onRowClick={orderRowClick}
                            disableColumnMenu
                            disableColumnSelector
                            disableColumnFilter
                            localeText={{
                                MuiTablePagination: {
                                    labelDisplayedRows: ({ from, to, count }) =>
                                        `${from} - ${to} of ${count === Number.MAX_VALUE ? "many" : count
                                        }`
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            }
            {orderId !== undefined &&
                <OrderView
                    orderId={orderId}
                />
            }
            <SearchCustomerModal
                open={openCustomerSearchModal}
                closeModal={(result) => {
                    setOpenCustomerSearchModal(false)
                    if (result) {
                        searchByCustomer(result)
                    }
                }}
            />
        </React.Fragment>
    );
}
