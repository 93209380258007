import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Button, Grid, InputAdornment, TextField, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { AXIOS_API_INSTANCE } from "../Constants"
import { send_authorized_request } from "../Constants/cognito"
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SelectCustomerModal from "./SelectCustomerModal"
import { MuiTelInput } from 'mui-tel-input'
import { useSnackbar } from "notistack";

export default function SearchCustomerModal({
    open,
    closeModal
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = React.useState(false)
    const [customers, setCustomers] = React.useState([])
    const [errorMessage, setErrorMessage] = React.useState('')
    const [customerSearch, setCustomerSearch] = React.useState('')
    const [customerNameSearch, setCustomerNameSearch] = React.useState('')

    const updateCustomerSearch = (e, extra) => {
        if (extra.nationalNumber.length < 11) {
            setCustomerSearch(e)
        }
    }
    const getCustomers = (e, include_phone, include_name) => {
        e.preventDefault()
        setErrorMessage('')
        setIsLoading(true)
        const search = {}
        if (include_phone && customerSearch.length > 3) { // Ignoring starting "+1 "
            search.cust_phone = customerSearch.substring(3).replaceAll(' ', '')
        }
        if (include_name && customerNameSearch.length > 0) {
            search.cust_name = customerNameSearch.toUpperCase()
        }
        send_authorized_request(() =>
            AXIOS_API_INSTANCE.get("customers", {
                params: {
                    search: search,
                },
            }).then(
                (customers) => {
                    console.log(customers)
                    if (customers.data.length > 0) {
                        setCustomers(customers.data.sort((a, b) => (a.cust_name || '').localeCompare(b.cust_name)))
                    } else {
                        setErrorMessage('No Customer Found')
                    }
                    setIsLoading(false)
                }).catch((error) => {
                    setIsLoading(false)
                    enqueueSnackbar(`Failed to get customers <${error.response?.data?.message ?? "Unknown Error Occurred"}>`, { variant: 'error' })
                })
        )
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth='md'
            >
                <DialogTitle>
                    {"Select Customer"}
                    <IconButton
                        aria-label="close"
                        onClick={() => closeModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ minWidth: '300px' }}>
                    <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
                        <Grid item xs>
                            <form onSubmit={(e) => getCustomers(e, true, false)}>
                                <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                    <Grid item xs>
                                        <MuiTelInput
                                            margin="dense"
                                            id="customersearch"
                                            label="Phone"
                                            type="tel"
                                            inputMode="numeric"
                                            variant="filled"
                                            disabled={isLoading}
                                            value={customerSearch}
                                            onChange={updateCustomerSearch}
                                            onlyCountries={['US']}
                                            defaultCountry="US"
                                            forceCallingCode
                                            disableDropdown
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Button variant={"outlined"} type="submit" disabled={customerSearch.replaceAll(' ', '').length !== 12} ><b>Customer Phone Lookup</b></Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>

                        <Grid item xs>
                            <form onSubmit={(e) => getCustomers(e, false, true)}>
                                <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                    <Grid item xs>
                                        <TextField
                                            margin="dense"
                                            id="customersearchname"
                                            label="Name"
                                            type="text"
                                            variant="filled"
                                            disabled={isLoading}
                                            value={customerNameSearch}
                                            onChange={(e) => setCustomerNameSearch(e.target.value.toUpperCase())}
                                            InputProps={{
                                                inputProps: { min: 1 },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {<SearchIcon />}
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <IconButton size="small" disabled={customerNameSearch.length === 0} onClick={() => setCustomerNameSearch('')}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Button variant={"outlined"} type="submit" ><b>Customer Name Lookup</b></Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                    <b><span style={{ color: 'red' }}>{errorMessage}</span></b>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
            <SelectCustomerModal
                open={customers.length >= 1}
                customers={customers}
                closeModal={(result) => {
                    if (result) {
                        closeModal(result)
                    }
                    setCustomers([])
                }}
            />
        </div>
    )
}
