import * as React from "react"
import { AXIOS_API_INSTANCE } from "../Constants"
import { send_authorized_request } from "../Constants/cognito"
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Grid, InputAdornment, TextField, IconButton } from "@mui/material"
import SelectCustomerModal from "./SelectCustomerModal"
import { useTrackedCustomer, useTrackedLocation } from "./UserState";
import { useNavigate } from "react-router-dom";
import { MuiTelInput } from 'mui-tel-input'
import { LoadingBackdropIndeterminate } from "./LoadingBackdrop";
import { useSnackbar } from "notistack";


function Dashboard(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [, setSelectedCustomer] = useTrackedCustomer();
    const [location,] = useTrackedLocation();
    const [isLoading, setIsLoading] = React.useState(false)
    const [customers, setCustomers] = React.useState([])
    const [errorMessage, setErrorMessage] = React.useState('')
    const [customerSearch, setCustomerSearch] = React.useState('')
    const [customerNameSearch, setCustomerNameSearch] = React.useState('')
    const navigate = useNavigate();
    console.log("RENDERING Dashboard")
    console.log(customerSearch)

    const updateCustomerSearch = (e, extra) => {
        if (extra.nationalNumber.length < 11) {
            setCustomerSearch(e)
        }
    }

    const cacheUnknownCustomer = () => {
        const cachedCustomer = {id: -1, cust_phone: '', cust_name: ''}
        if (customerSearch.length > 3) { // Ignoring starting "+1 "
            cachedCustomer.cust_phone = customerSearch.substring(3).replaceAll(' ', '')
        }
        if (customerNameSearch.length > 0) {
            cachedCustomer.cust_name = customerNameSearch.toUpperCase()
        }
        setSelectedCustomer(cachedCustomer)
        navigate('/Order')
    }

    const getCustomers = (e, include_phone, include_name) => {
        e.preventDefault()
        setErrorMessage('')
        setIsLoading(true)
        const search = {}
        if (include_phone && customerSearch.length > 3) { // Ignoring starting "+1 "
            search.cust_phone = customerSearch.substring(3).replaceAll(' ', '')
        }
        if (include_name && customerNameSearch.length > 0) {
            search.cust_name = customerNameSearch.toUpperCase()
        }
        send_authorized_request(() =>
            AXIOS_API_INSTANCE.get("customers", {
                params: {
                    search: search,
                },
            }).then(
                (customers) => {
                    console.log(customers)
                    if (customers.data.length > 0) {
                        setCustomers(customers.data.sort((a, b) => (a.cust_name || '').localeCompare(b.cust_name)))
                    } else {
                        setErrorMessage('No Customer Found')
                    }
                    setIsLoading(false)
                }).catch((error) => {
                    setIsLoading(false)
                    enqueueSnackbar(`Failed to get customers <${error.response?.data?.message ?? "Unknown Error Occurred"}>`, { variant: 'error' })
                })
        )
    }

    return (
        <React.Fragment>
            <LoadingBackdropIndeterminate
                open={isLoading}
                text="Loading Customers"
            />
            <Box sx={{ margin: 'auto', mt: 1 }}>
                <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs>
                        <h3>Welcome to ParkingLot Checkout</h3>
                    </Grid>
                    <Grid item xs>
                        <Button variant="contained" disabled={location.id === 'all'} onClick={() => {
                            setSelectedCustomer(undefined);
                            navigate('/Order')
                        }}>
                            Quick Sale
                        </Button>
                    </Grid>
                    {location.id === 'all' &&
                        <Grid item xs>
                            <span style={{ color: 'red' }}>Select a Location</span>
                        </Grid>
                    }
                    <Grid item xs>
                        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
                            <Grid item xs>
                                <form onSubmit={(e) => getCustomers(e, true, false)}>
                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                        <Grid item xs>
                                            <MuiTelInput
                                                margin="dense"
                                                id="customersearch"
                                                label="Phone"
                                                type="tel"
                                                inputMode="numeric"
                                                variant="filled"
                                                disabled={isLoading || location.id === 'all'}
                                                value={customerSearch}
                                                onChange={updateCustomerSearch}
                                                onlyCountries={['US']}
                                                defaultCountry="US"
                                                forceCallingCode
                                                disableDropdown
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Button variant={"outlined"} type="submit" disabled={location.id === 'all' || customerSearch.replaceAll(' ', '').length !== 12} ><b>Customer Phone Lookup</b></Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>

                            <Grid item xs>
                                <form onSubmit={(e) => getCustomers(e, false, true)}>
                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                        <Grid item xs>
                                            <TextField
                                                margin="dense"
                                                id="customersearchname"
                                                label="Name"
                                                type="text"
                                                variant="filled"
                                                disabled={isLoading || location.id === 'all'}
                                                value={customerNameSearch}
                                                onChange={(e) => setCustomerNameSearch(e.target.value.toUpperCase())}
                                                InputProps={{
                                                    inputProps: { min: 1 },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {<SearchIcon />}
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <IconButton size="small" disabled={customerNameSearch.length === 0} onClick={() => setCustomerNameSearch('')}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Button variant={"outlined"} type="submit" disabled={location.id === 'all'} ><b>Customer Name Lookup</b></Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <b><span style={{ color: 'red' }}>{errorMessage}</span></b>
                    </Grid>
                    {errorMessage &&
                        <Grid item xs>
                            <Button color="info" variant={"contained"} onClick={cacheUnknownCustomer} ><b><i>Quick Sale - Save Customer Information</i></b></Button>
                        </Grid>
                    }
                </Grid>
            </Box>
            <SelectCustomerModal
                open={customers.length >= 1}
                customers={customers}
                closeModal={(result) => {
                    if (result) {
                        setSelectedCustomer(result)
                        navigate('/Order')
                    }
                    setCustomers([])
                }}
            />
        </React.Fragment >
    )
}

export default Dashboard
