import * as React from 'react';
import { Container, Box } from '@mui/material';
import styled from "@emotion/styled";
import { motion } from "framer-motion";

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
    opacity: 1,
    y: 0,
    transition: {
        duration: 0.6,
        ease: easing,
        delay: 0.16,
    },
};


const RootStyle = styled("div")({
    background: "rgb(249, 250, 251)",
    height: "100vh",
    display: "grid",
    placeItems: "center",
});

const ContentStyle = styled("div")({
    maxWidth: 800,
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "#fff",
});



export default function ErrorPage({ error, resetErrorBoundary }) {
    return (
        <RootStyle>
            <Container>
                <ContentStyle>

                    <Box
                        component={motion.div}
                        animate={{
                            transition: {
                                staggerChildren: 0.55,
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 3,
                            }}
                            component={motion.div}
                            initial={{ opacity: 0, y: 40 }}
                            animate={animate}
                        >
                            <h3>Oh no! Something went wrong.</h3>
                            <p>Error: {error.message}</p>
                            <p>{error.stack}</p>
                        </Box>
                    </Box>
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}