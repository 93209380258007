import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Grid, IconButton } from '@mui/material';


export default function ConfirmModal({ open, text, callback }) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={() => callback(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    Confirm
                    <IconButton
                        aria-label="close"
                        onClick={() => callback(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <span>{text}</span>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="right" sx={{ padding: "0px 10px 10px 30px" }}>
                        <Grid item xs="auto">
                            <Button variant='outlined' color='error' onClick={() => callback(false)} sx={{ mr: 1 }}>
                                <CancelIcon /> <Box sx={{ pl: 1 }}><span>Cancel</span></Box>
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Button variant="contained" autoFocus onClick={() => callback(true)}>
                                <CheckIcon /> <Box sx={{ pl: 1 }}><span>Confirm</span></Box>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}