import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { AXIOS_API_INSTANCE } from '../Constants';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { send_authorized_request } from '../Constants/cognito';
import { useSnackbar } from 'notistack';
import { useTrackedAllLocations } from './UserState';
import ConfirmModal from '../Utils/ConfirmModal';


export default function ItemModal({ item, open, closeModal, creatingNewItem }) {
    const { enqueueSnackbar } = useSnackbar();
    const [locations,] = useTrackedAllLocations();
    const [my_item, setItem] = React.useState(item || {});
    const [confirmation, setConfirmation] = React.useState({
        open: false,
        text: '',
        callback: null
    })
    React.useEffect(() => {
        setItem({ ...item });
    }, [item]);

    const handleChange = (prop) => (event) => {
        setItem({ ...my_item, [prop]: event.target.value })
    };

    const handleLocationAvailabilityChange = (location_id) => () => {
        if (my_item.pricing[location_id]) {
            my_item.pricing[location_id] = undefined
        } else {
            my_item.pricing[location_id] = {}
        }
        setItem({ ...my_item })
    }

    const handlePricingChange = (location_id, tier) => (event) => {
        my_item.pricing[location_id] = my_item.pricing[location_id] || {}
        my_item.pricing[location_id][tier] = event.target.value
        setItem({ ...my_item })
    };

    const save = (event) => {
        console.log(my_item)
        if (creatingNewItem) {
            send_authorized_request(() => AXIOS_API_INSTANCE.post(`items`, my_item).then(() => {
                enqueueSnackbar('Successfully created item', { variant: 'success' })
                closeModal(true)
            }).catch((error) => {
                enqueueSnackbar(`Failed to create item <${error.response?.data?.message ?? "Unknown Error Occurred"}>`, { variant: 'error' })
            }))
        } else {
            send_authorized_request(() => AXIOS_API_INSTANCE.post(`items/${my_item.id}`, my_item).then(() => {
                enqueueSnackbar('Successfully updated item', { variant: 'success' })
                closeModal(true)
            }).catch((error) => {
                enqueueSnackbar(`Failed to update item <${error.response?.data?.message ?? "Unknown Error Occurred"}>`, { variant: 'error' })
            }))
        }
    }

    const delete_item = (event) => {
        console.log('delete item')
        event.stopPropagation();
        const text = `Are you sure you want to delete this item?`
        setConfirmation({
            open: true,
            text: text,
            callback: (confirmation) => {
                if (confirmation) {
                    send_authorized_request(() =>
                        AXIOS_API_INSTANCE.delete(`items/${my_item.id}`).then(
                            () => {
                                enqueueSnackbar('Successfully deleted item', { variant: 'success' })
                                closeModal(true)
                            }
                        ).catch((error) => {
                            enqueueSnackbar(`Failed to delete item <${error.response?.data?.message ?? "Unknown Error Occurred"}>`, { variant: 'error' })
                        })
                    )
                }
                setConfirmation({ text: text, open: false })
            }
        })
    }

    const sorted_locations = locations.sort((a, b) => a.name.localeCompare(b.name))

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    {creatingNewItem ? "Creating Item" : "Modifying Item"}
                    <IconButton
                        aria-label="close"
                        onClick={() => closeModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ minWidth: '310px' }}>
                    <Grid container direction="column">
                        <Grid item>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={my_item?.name || ''}
                                onChange={handleChange('name')}
                                required={true}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="type"
                                label="Type"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={my_item?.type || ''}
                                onChange={handleChange('type')}
                                required={true}
                            />
                        </Grid>
                        <Grid item>
                            <Box sx={{ minWidth: 120, mt: 1 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Quantity of Measure</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={my_item?.quantity_of_measure || ''}
                                        label="Quantity of Measure"
                                        onChange={handleChange('quantity_of_measure')}
                                    >
                                        <MenuItem value={'Scoop'}>Scoop</MenuItem>
                                        <MenuItem value={'Buckets'}>Buckets</MenuItem>
                                        <MenuItem value={'Flat Rate'}>Flat Rate</MenuItem>
                                        <MenuItem value={'Gallon'}>Gallon</MenuItem>
                                        <MenuItem value={'Each'}>Each</MenuItem>
                                        <MenuItem value={'Bag'}>Bag</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="sku"
                                label="SKU"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={my_item?.sku || ''}
                                onChange={handleChange('sku')}
                                required={true}
                            />
                        </Grid>
                        {sorted_locations.map((location) =>
                            <Grid item key={`${location.id}`}>
                                <Box sx={{ border: 1, m: 1, p: 1, borderColor: 'rgba(0,0,0,0.87)' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{ fontSize: '1.17rem', fontWeight: 'bold', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: 0, marginInlineEnd: 0 }}>{location.name}</span>
                                        <FormControlLabel
                                            control={<Checkbox checked={(my_item?.pricing || {})[location.id] !== undefined} />}
                                            label="Available"
                                            labelPlacement='end'
                                            onChange={handleLocationAvailabilityChange(location.id)}
                                            sx={{ display: 'flex', 'justify-content': 'space-between' }}
                                        />
                                    </Box>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="retail-pricing"
                                        label="Retail Pricing"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        value={(my_item?.pricing || {})[location.id]?.Retail || 0}
                                        onChange={handlePricingChange(location.id, 'Retail')}
                                        disabled={(my_item?.pricing || {})[location.id] === undefined}
                                    />
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="PCP-pricing"
                                        label="PCP Pricing"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        value={(my_item?.pricing || {})[location.id]?.PCP || 0}
                                        onChange={handlePricingChange(location.id, 'PCP')}
                                        disabled={(my_item?.pricing || {})[location.id] === undefined}
                                    />
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="CTA Bronze Tier-pricing"
                                        label="CTA Bronze Tier Pricing"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        value={(my_item?.pricing || {})[location.id]?.['CTA Bronze Tier'] || 0}
                                        onChange={handlePricingChange(location.id, 'CTA Bronze Tier')}
                                        disabled={(my_item?.pricing || {})[location.id] === undefined}
                                    />
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="CTA Silver Tier-pricing"
                                        label="CTA Silver Tier Pricing"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        value={(my_item?.pricing || {})[location.id]?.['CTA Silver Tier'] || 0}
                                        onChange={handlePricingChange(location.id, 'CTA Silver Tier')}
                                        disabled={(my_item?.pricing || {})[location.id] === undefined}
                                    />
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="CTA Gold Tier-pricing"
                                        label="CTA Gold Tier Pricing"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        value={(my_item?.pricing || {})[location.id]?.['CTA Gold Tier'] || 0}
                                        onChange={handlePricingChange(location.id, 'CTA Gold Tier')}
                                        disabled={(my_item?.pricing || {})[location.id] === undefined}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between" sx={{ padding: "0px 30px 10px 30px" }}>
                        <Grid item xs="auto">
                            {!creatingNewItem &&
                                <Button variant='outlined' color='error' onClick={delete_item} sx={{ mr: 1 }}>
                                    <DeleteIcon /> <Box sx={{ pl: 1 }}><span>Delete</span></Box>
                                </Button>
                            }
                        </Grid>
                        <Grid item xs="auto">
                            <Button variant="contained" autoFocus onClick={save}>
                                {creatingNewItem ? "Create" : "Save"}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
                <ConfirmModal
                    {...confirmation}
                />
            </Dialog>
        </div>
    );
}