import React from "react";
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartCheckoutSharpIcon from '@mui/icons-material/ShoppingCartCheckoutSharp';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WindowIcon from '@mui/icons-material/Window';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ListIcon from '@mui/icons-material/List';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import {
  Link as RouterLink,
} from 'react-router-dom';
import { FormControl, InputLabel, Link, MenuItem, Select, Typography } from "@mui/material";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useTrackedAllLocations, useTrackedLocation, useTrackedUser, useTrackedDarkMode } from "./UserState";
import { all_locations_object } from "../Constants";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useTrackedCart } from "./CheckoutState";
import { logout } from "../Constants/cognito";

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function Copyright() {
  return (
    <Typography variant="body2" color={item.color} align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://cedartitans.com/">
        Cedar Titans LLC
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const RoutedLink = React.forwardRef(function Link(itemProps, ref) {
  return <RouterLink to="#" ref={ref} {...itemProps} role={undefined} />;
});



export default function Navigator(props) {
  const [my_user,] = useTrackedUser();
  const [selectedLocation, setSelectedLocation] = useTrackedLocation();
  const [locations,] = useTrackedAllLocations();
  const [, setCart] = useTrackedCart();
  const [user,] = useTrackedUser();
  const [darkMode, setDarkMode] = useTrackedDarkMode();
  const [state,] = React.useState({
    drawer_open: true,
    categories: [
      {
        id: 'Order',
        children: [
          { id: 'Order', displayName: 'Create Order', icon: <ShoppingCartCheckoutSharpIcon /> },
          { id: 'Orders', displayName: 'Orders', icon: <ReceiptLongIcon /> },
          { id: 'Reports', displayName: 'Reports', icon: <AssessmentIcon /> },
        ],
      },
      {
        id: 'Config',
        children: [
          { id: 'Users', icon: <AccountCircleIcon />, },
          { id: 'Items', icon: <WindowIcon />, user_attribute: 'permissions_items_admin' },
          { id: 'Config', displayName: 'Client Configurations', icon: <AdminPanelSettingsIcon />, user_attribute: 'permissions_client_config_admin' },
        ],
      },
      // {
      //   id: 'Config',
      //   children: [
      //   ],
      // },
    ]
  })

  const handleLocationChange = (event, new_loc) => {
    console.log(event)
    console.log(new_loc)
    localStorage.setItem("plot_selected_location", event.target.value)
    setSelectedLocation(new_loc.props.object)
    setCart({ cart: [], open: false })
  }

  const updateDarkMode = (event) => {
    event.stopPropagation();
    localStorage.setItem("darkMode", !darkMode)
    setDarkMode(!darkMode)
  }
  const my_darkMode = darkMode
  const location_options = (locations || []).filter((loc) => (my_user.location_ids || []).includes(loc.id)).concat(all_locations_object).map((loc) => {
    return <MenuItem object={loc} key={loc.id} value={loc.id}>{loc.short_name}</MenuItem>
  })

  console.log(props.currenttab)
  const filtered_categories = state.categories.filter((category) =>
    (category.user_attribute === undefined || props.my_user[category.user_attribute])
  )

  return (
    <Drawer variant="permanent" {...props}>
      <OverlayScrollbarsComponent>
        <Box>
          <List disablePadding>
            <ListItem key="reaper" sx={{ ...item, ...itemCategory, fontSize: 21, color: '#fff' }} component={RoutedLink} to={"/Dashboard"}>
              <Box>
                <span>ParkingLot Checkout</span><br />
              </Box>
            </ListItem>
            <ListItem key="home" sx={{ ...item, ...itemCategory }} component={RoutedLink} to={"/Dashboard"} onClick={props.onClose}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>Home</ListItemText>
            </ListItem>
            <ThemeProvider theme={darkTheme}>
              <FormControl fullWidth sx={{ left: 0 }}>
                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedLocation.id}
                  label="Location"
                  onChange={handleLocationChange}
                >
                  {location_options}
                </Select>
              </FormControl>
            </ThemeProvider>
            {filtered_categories.map(({ id, children, user_attribute }, index) => {
              return (<Box key={id || index} sx={{ bgcolor: '#101F33' }}>
                {id &&
                  <ListItem key={id} sx={{ py: 1, px: 3 }}>
                    <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
                  </ListItem>
                }
                {id === undefined &&
                  <Box sx={{ height: '10px' }} />
                }
                {children.map(({ id: childId, icon, displayName, user_attribute }) => {
                  if (user_attribute && !my_user[user_attribute] && !my_user.root_admin) {
                    return undefined
                  }
                  return (
                    <ListItem key={childId} selected={props.currenttab.split('/').includes(childId.toLowerCase())} sx={item} button component={RoutedLink} to={"/" + childId} onClick={props.onClose}>
                      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                      <ListItemText primary={displayName || childId} />
                    </ListItem>
                  )
                })}

                <Divider sx={{ mt: 2 }} />
              </Box>)
            }
            )}
            <Box sx={{ bgcolor: '#101F33' }}>
              <ListItem sx={{ py: 1, px: 3 }}>
                <ListItemText sx={{ color: '#fff' }}>Logged in as: {user.name}</ListItemText>
              </ListItem>
              <ListItem sx={item} button component={RoutedLink} onClick={logout}>
                <ListItemIcon><LogoutIcon /></ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItem>
              <ListItem sx={item} button component={RoutedLink} to={"/RecentOrders"} onClick={props.onClose}>
                <ListItemIcon><ListIcon /></ListItemIcon>
                <ListItemText primary={"Recently Viewed Orders"} />
              </ListItem>
              <ListItem sx={item} button onClick={updateDarkMode}>
                <ListItemIcon>{my_darkMode ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</ListItemIcon>
                <ListItemText primary={"Dark Mode"} />
              </ListItem>
              <Divider sx={{ mt: 2 }} />
            </Box>
          </List>
          <Box component="footer" sx={{ pt: 1, pb: 2, mt: "auto" }}>
            <Copyright />
          </Box>
        </Box>
      </OverlayScrollbarsComponent >
    </Drawer >
  );
}