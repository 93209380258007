import React from 'react';
import { createContainer } from 'react-tracked';

const useValueCart = () => React.useState({ cart: [], open: false });

export const { Provider: CartState, useTracked: useTrackedCart } = createContainer(useValueCart, { concurrentMode: true });

export const find_item = (cart, item_id) => {
    var found_item = undefined
    cart.cart.forEach((item) => {
        if (item.id === item_id) {
            found_item = item
            return
        }
    })
    return found_item
}

export const update_cart = (cart, fresh_item, quantity, setter) => {
    var found_item = false
    if(quantity === 0) {
        cart.cart = cart.cart.filter((x) => x.id !== fresh_item.id)
    } else {
        cart.cart.forEach((item) => {
            if (item.id === fresh_item.id) {
                found_item = true
                item.quantity = quantity
            }
        })
        if (!found_item && quantity > 0) {
            cart.cart.push({ ...fresh_item, quantity: quantity })
        }
    }
    setter({ ...cart })
}