import './App.css';


import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navigator from './Components/Navigator';
import Header from './Components/Header';

import {
  Route,
  Routes,
  matchPath,
  useLocation,
} from 'react-router-dom';
import Login from './Components/Login';
import { isLoggedIn, foundUser, send_authorized_request, logout } from './Constants/cognito'
import { CircularProgress } from '@mui/material';
import { all_locations_object, AXIOS_API_INSTANCE } from './Constants';
import axios from 'axios';
import { useTrackedLocation, useTrackedAllLocations, useTrackedUser, useTrackedDarkMode } from './Components/UserState'
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import Dashboard from './Components/Dashboard';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import Order from './Components/Order';
import Users from './Components/Users';
import Orders from './Components/Orders';
import Items from './Components/Items';
import Config from './Components/Config';
import Reports from './Components/Reports';

const getTheme = (darkMode = false) => {
  let theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        light: '#a7d378',
        main: '#84C243',
        dark: '#689a32',
      },
      green: {
        light: '#4caf50',
        main: '#2e7d32',
        dark: '#1b5e20',
        contrastText: '#ffffff'
      },
      orange: {
        light: '#ff9800',
        main: '#ed6c02',
        dark: '#e65100',
        contrastText: '#ffffff'
      },
      grey: {
        light: '#CFCFCF',
        main: '#B1B1B1',
        dark: '#9E9E9E',
        contrastText: '#ffffff'
      }
    },
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
      },

    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });

  return {
    ...theme,
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#081627',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontWeight: 'bold',
            // textShadow: 'grey 0px 0px 10px'
          },
          contained: {
            boxShadow: 'none',
            '&:active': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            marginLeft: theme.spacing(1),
          },
          indicator: {
            height: 3,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            backgroundColor: theme.palette.common.white,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            margin: '0 16px',
            minWidth: 0,
            padding: 0,
            [theme.breakpoints.up('md')]: {
              padding: 0,
              minWidth: 0,
            },
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            padding: 12
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: theme.spacing(1),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 4,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgb(255,255,255,0.15)',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#4fc3f7',
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: 14,
            fontWeight: theme.typography.fontWeightMedium,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: 'inherit',
            minWidth: 'auto',
            marginRight: theme.spacing(2),
            '& svg': {
              fontSize: 20,
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: 32,
            height: 32,
          },
        },
      },
    },
  };
}

const drawerWidth = 256;

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

const setup_reroute_handler = (setLogin, set_my_user, set_locations) => {
  window.addEventListener('custom_logout', e => {
    setLogin(false)
    set_my_user({})
    set_locations([])
    localStorage.removeItem("recentOrders");
  }, true)
}

const get_my_user = (set_my_user, set_locations, setSelectedLocation, cookies, setCookie, enqueueSnackbar) => {
  send_authorized_request(() => axios.all([AXIOS_API_INSTANCE.get("users/my_user")]).then(
    axios.spread((user) => {
      console.log(user)
      // setCookie('CloudFront-Key-Pair-Id', user.data.cf_cookies['CloudFront-Key-Pair-Id'], { path: '/' })
      // setCookie('CloudFront-Policy', user.data.cf_cookies['CloudFront-Policy'], { path: '/' })
      // setCookie('CloudFront-Signature', user.data.cf_cookies['CloudFront-Signature'], { path: '/' })
      set_my_user({ ...user.data.user });
      set_locations(user.data.locations)
      const current_location = user.data.locations.filter((loc) => user.data.user.location_ids.includes(loc.id)).find((loc) => loc.id.toString() === localStorage.getItem("plot_selected_location") && user.data.user.location_ids.includes(loc.id.toString()))
      setSelectedLocation(current_location || all_locations_object)
    })
  ).catch((error) => {
    console.log(error);
    logout()
    enqueueSnackbar(`${error.response?.data?.message ?? "Unknown Error Occurred"}`, { variant: 'error' })
  }));
}

export default function App() {
  const [initBodyOverlayScrollbars,] =
    useOverlayScrollbars({
      defer: true,
      options: { nativeScrollbarsOverlaid: { initialize: false } }
    });
  React.useEffect(() => {
    console.log("INITIALIZING SCROLLBARS")
    initBodyOverlayScrollbars(document.body);
  }, [initBodyOverlayScrollbars]);

  const [darkMode,] = useTrackedDarkMode();
  const [theme, setTheme] = React.useState(getTheme(darkMode))
  const [cookies, setCookie] = useCookies()
  const { enqueueSnackbar } = useSnackbar();
  const [login, setLogin] = React.useState(false)
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [my_user, set_my_user] = useTrackedUser();
  const [, set_locations] = useTrackedAllLocations();
  const [mfaStatus, setMfaStatus] = React.useState(undefined);
  console.log("MFA STATUS")
  console.log(mfaStatus)
  const [selectedLocation, setSelectedLocation] = useTrackedLocation();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  setup_reroute_handler(setLogin, set_my_user, set_locations)
  console.log('RENDERING APP')
  console.log('SELECTED LOCATION:')
  console.log(selectedLocation)

  React.useEffect(() => {
    setTheme(getTheme(darkMode));
  }, [darkMode]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const routeMatch = useRouteMatch(['/dashboard', '/order', '/orders', '/orders/:id', '/recentorders', '/login', '/users', '/customers', '/items', '/config']);
  const currenttab = routeMatch?.pattern?.path || "";
  console.log(currenttab)
  const handle_login = () => {
    isLoggedIn(setLogin, login, set_my_user, get_my_user, set_locations, setSelectedLocation, cookies, setCookie, enqueueSnackbar, mfaStatus, setMfaStatus)
  }
  handle_login()
  if ((!foundUser() && !login)) {
    return (
      <ThemeProvider theme={getTheme(false)}>
        <Login login_callback={handle_login}></Login>
      </ThemeProvider>
    )
  }
  if ((foundUser() && !login) || my_user?.id === undefined) {
    let circle_size = Math.round(((window.innerWidth + window.innerHeight) / 2) * .10).toString()
    const circle_px = circle_size + 'px'
    return (<Box
      sx={{
        margin: '10% auto',
        display: 'grid',
        justifyContent: 'center',
        rowGap: '20px'
      }}
    >
      <Box
        component="img"
        sx={{
          display: 'block',
          height: (circle_size * 2) + 'px'
        }}
        src="/cedartitanslogo.png"
      />
      <CircularProgress style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', height: circle_px, width: circle_px }} color="inherit" />
    </Box>)
  }

  console.log('FULLY RENDERING APP')
  console.log(login)
  return (
    <ThemeProvider theme={theme}>

      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        >
          {isMdUp ? null : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              currenttab={currenttab}
              my_user={my_user}
            />
          )}

          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}
            currenttab={currenttab}
            my_user={my_user}
          />
        </Box>

        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Header onDrawerToggle={handleDrawerToggle} currenttab={currenttab} mfaStatus={mfaStatus} setMfaStatus={setMfaStatus} />
          <Box component="main" sx={{ flex: 1, pt: '12px', pb: '20px', px: '12px', display: 'flex' }}> {/*bgcolor: '#eaeff1' */}
            <Routes>
              <Route path="*" element={<Dashboard />} />
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/Order" element={<Order />} />
              <Route path="/Orders" element={<Orders />}>
                <Route path={':orderId'} />
                <Route path={''} />
              </Route>
              <Route path="/RecentOrders" element={<Orders viewRecentOrders={true} />}>
                <Route path={':orderId'} />
                <Route path={''} />
              </Route>
              <Route path="/Users" element={<Users />} />
              <Route path="/Items" element={<Items />} />
              <Route path="/Config" element={<Config />} />
              <Route path="/Reports" element={<Reports />} />
            </Routes>
          </Box>

        </Box>

      </Box>
    </ThemeProvider>
  );
}