import * as React from "react"
import Button from "@mui/material/Button"
import { Drawer, TextField } from "@mui/material"
import { Box } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import { update_cart, useTrackedCart } from "./CheckoutState"
import { formatPhoneNumber, moneyFormat, getItemPricing } from "../Utils"
import QuantityModal from "./QuantityModal"
import { Grid } from "@mui/material"
import { IconButton } from "@mui/material"
import PayModal from "./PayModal"
import { useTrackedCustomer, useTrackedLocation } from "./UserState"
import { useTheme } from "@emotion/react";


export default function CartDrawer(props) {
    const theme = useTheme();
    const [customer, ] = useTrackedCustomer();
    const [cart, setCart] = useTrackedCart()
    const [location,] = useTrackedLocation()
    const [selectedItem, setSelectedItem] = React.useState(undefined)
    const [openPaymentModal, setOpenPaymentModal] = React.useState(false)

    const closePaymentModal = (changes) => {
        setOpenPaymentModal(false)
        if (changes) {
            setCart({ cart: [], open: false })
        }
    }

    const total_cost = cart.cart.length === 0 ? 0 : cart.cart.reduce((accumulator, item) => accumulator + Math.round(parseFloat(getItemPricing(item, customer, location) * item.quantity) * 100) / 100, 0)

    return (
        <React.Fragment>
            <Drawer
                anchor={"right"}
                open={cart.open}
                onClose={() => setCart({ ...cart, open: false })}
                sx={{ width: `${Math.min(375, window.innerWidth - 10)}px`, flexShrink: 0 }}
                PaperProps={{ variant: "outlined", style: { width: `${Math.min(375, window.innerWidth - 10)}px`, backgroundColor: theme.palette.background.default} }}
            >
                <Box width={'100%'} paddingLeft={1} paddingRight={1}>
                    <IconButton
                        aria-label="close"
                        onClick={() => setCart({ ...cart, open: false })}
                        sx={{
                            position: 'absolute',
                            left: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h2 style={{ textAlign: 'center' }}>Cart<Box sx={{ paddingLeft: 1, display: 'inline', verticalAlign: 'middle' }}><ShoppingCartIcon /></Box></h2>
                    {!customer &&
                        <Box sx={{ paddingBottom: 2 }}>
                            <span><b>No Customer Selected</b></span>
                        </Box>
                    }
                    {customer && customer.id === -1 &&
                        <Box sx={{ paddingBottom: 2 }}>
                            <span><b><i>CACHING</i> Customer:</b></span><br />
                            <span style={{ marginLeft: 16 }}>{customer.cust_name}</span><br />
                            <span style={{ marginLeft: 16 }}>{formatPhoneNumber(customer.cust_phone)}</span>
                        </Box>
                    }
                    {customer && customer.id !== -1 &&
                        <Box sx={{ paddingBottom: 2 }}>
                            <span><b>Customer:</b></span><br />
                            <span style={{ marginLeft: 16 }}>{customer.cust_name}</span><br />
                            <span style={{ marginLeft: 16 }}>{formatPhoneNumber(customer.cust_phone)}</span>
                        </Box>
                    }
                    <Grid container columns={10} alignItems={"center"}>
                        <Grid item xs={4}>
                            <span><b>Item</b></span>
                        </Grid>
                        <Grid item xs={2}>
                            <span><b>Qty</b></span>
                        </Grid>
                        <Grid item xs={3}>
                            <span><b>Cost</b></span>
                        </Grid>
                        <Grid item xs={1}>
                            <span><b>Del</b></span>
                        </Grid>
                    </Grid>
                    <hr />
                    {cart.cart.map((item) =>
                        <Box key={item.id}>
                            <Grid container columns={10} alignItems={"center"}>
                                <Grid item xs={4}>
                                    <span>{item.name} ({item.quantity_of_measure})</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        margin="dense"
                                        id="quantity"
                                        type="number"
                                        inputMode="numeric"
                                        fullWidth
                                        variant="filled"
                                        hiddenLabel
                                        size="small"
                                        disabled={selectedItem}
                                        value={item.quantity}
                                        onSelect={() => setSelectedItem(item)}
                                        sx={{ margin: 0 }}
                                        inputProps={{
                                            sx: { pl: '3px', pr: '3px' }
                                        }}
                                    />
                                    {/* <span>{item.quantity}</span> */}
                                </Grid>
                                <Grid item xs={3}>
                                    <span>${moneyFormat(getItemPricing(item, customer, location) * item.quantity)}</span>
                                </Grid>
                                <Grid item xs={1} onClick={() => update_cart(cart, item, 0, setCart)}>
                                    <IconButton>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <hr />
                        </Box>
                    )}
                    <Grid container justifyContent={"flex-end"} sx={{ textAlign: 'right' }}>
                        <Grid item xs={9}>
                            <span>Total Before Tax:</span>
                        </Grid>
                        <Grid item xs={3}>
                            <span>${moneyFormat(total_cost)}</span>
                        </Grid>
                        <Grid item xs={9}>
                            <span>Tax:</span>
                        </Grid>
                        <Grid item xs={3}>
                            <span>{location.id === 'all' ? '???' : '$' + moneyFormat(Math.round(total_cost * location.tax_rate) / 100)}</span>
                        </Grid>
                        <Grid item xs={9}>
                            <span>Total:</span>
                        </Grid>
                        <Grid item xs={3}>
                            <span>${moneyFormat(total_cost + (Math.round(total_cost * location.tax_rate) / 100))}</span>
                        </Grid>
                    </Grid>
                    <Button sx={{ width: '100%', margin: '0 2 0 2', paddingTop: 1, paddingBottom: 1, fontSize: 20 }} variant="contained" disabled={cart.cart.length === 0 || location.id === 'all'} onClick={() => setOpenPaymentModal(true)}>Pay</Button>
                    {location.id === 'all' &&
                        <Box width={'100%'} sx={{ textAlign: 'center' }}>
                            <span style={{ color: 'red' }}>Please Select Location</span>
                        </Box>
                    }
                </Box>
            </Drawer>
            <QuantityModal
                cart={cart}
                item={selectedItem}
                open={selectedItem !== undefined}
                closeModal={(result) => {
                    if (result) {
                        update_cart(cart, selectedItem, result, setCart)
                    }
                    setSelectedItem(undefined)
                }}
            />
            <PayModal
                cart={cart}
                new_order={true}
                customer={customer}
                open={openPaymentModal}
                closeModal={closePaymentModal}
            />
        </React.Fragment>
    );
}
