import * as React from "react"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import {
    DataGrid,
} from "@mui/x-data-grid"
import { AXIOS_API_INSTANCE } from "../Constants"

import UserModal from "./UserModal"
import { send_authorized_request } from "../Constants/cognito"
// import { SimpleCustomToolbar } from "./CustomToolbar"
import UserAvatar from "./UserAvatar"
import { useTrackedUser } from "./UserState";


const columns = [
    {
        field: 'image',
        headerName: '',
        width: 50,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
            return <UserAvatar user={params.row} />
        }
    },
    {
        field: "name",
        headerName: "Name",
        width: 125,
        editable: false,
        filterable: false,
    },
    {
        field: "email",
        headerName: "Email",
        // width: 250,
        flex: 1,
        editable: false,
        filterable: false,
    },
    // {
    //     field: "permissions",
    //     headerName: "Permissions",
    //     flex: 1,
    //     editable: false,
    //     filterable: false,
    //     sortable: false,
    //     renderCell: (params) => {
    //         const permission_list = []
    //         if (params.row.root_admin) { permission_list.push('Root admin') }
    //         if (params.row.permissions_user_admin) { permission_list.push('User admin') }
    //         if (params.row.permissions_inventory_admin) { permission_list.push('Inventory admin') }
    //         if (params.row.permissions_rental_configs_admin) { permission_list.push('Configuration admin') }
    //         if (params.row.permissions_checkout_admin) { permission_list.push('Checkout admin') }
    //         if (params.row.permissions_checkout_user) { permission_list.push('Checkout user') }
    //         return (
    //             <span>{permission_list.join(', ')}</span>
    //         )
    //     }
    // },
]

function Users(props) {
    const [my_user,] = useTrackedUser();
    const [rowData, setRowData] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [userModal, setUserModal] = React.useState({
        open: false,
        user: {},
        creatingNewUser: false,
    })

    const userRowClick = (params) => {
        setUserModal({ user: params.row, open: true, creatingNewUser: false })
    }
    const createUserClick = (params) => {
        setUserModal({ user: {}, open: true, creatingNewUser: true })
    }
    const closeUserModal = (changes) => {
        setUserModal({ ...userModal, open: false })
    }
    const closeUserModalWithChanges = (changes) => {
        setUserModal({ ...userModal, open: false })
        if (changes) {
            getUsers()
        }
    }

    const getUsers = () => {
        setIsLoading(true)
        send_authorized_request(() =>
            AXIOS_API_INSTANCE.get("users")
                .then((user) => {
                    console.log(user)
                    setRowData(user.data)
                    setIsLoading(false)
                })
        )
    }


    React.useEffect(() => {
        getUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <Grid container space={1}>
                <Grid item xs>
                    <Grid container sx={{ mb: 1 }}>
                        {/* <Grid item>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="usersearch"
                                label="Search users"
                                type="text"
                                variant="filled"
                                disabled={isLoading}
                                value={userSearch}
                                onChange={updateUserSearch}
                                InputProps={{
                                    inputProps: { min: 1 },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {<SearchIcon />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid> */}
                        <Grid item>
                            <Button onClick={createUserClick} disabled={!my_user.root_admin && !my_user.permissions_user_admin} variant="contained" color="primary"><AddCircleSharpIcon />  Add User</Button>
                        </Grid>
                    </Grid>
                    <DataGrid
                        density="compact"
                        disableColumnMenu
                        disableColumnSelector
                        disableColumnFilter
                        rows={rowData}
                        rowCount={rowData.length}
                        columns={columns}
                        disableRowSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
                        autoHeight={true}
                        loading={isLoading}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        pagination
                        onRowClick={userRowClick}
                    />
                    <UserModal
                        open={userModal.open}
                        user={userModal.user || {}}
                        closeModal={closeUserModal}
                        closeModalWithChanges={closeUserModalWithChanges}
                        creatingNewUser={userModal.creatingNewUser}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Users
