import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'overlayscrollbars/overlayscrollbars.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from "react-error-boundary";
import { API_URL, AXIOS_API_INSTANCE } from "./Constants";
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { CookiesProvider } from 'react-cookie';
import { AllLocationState, CustomerState, DarkModeState, LocationState, RecentOrdersState, UserState } from './Components/UserState';
import ErrorPage from './Components/ErrorPage';
import { CartState } from './Components/CheckoutState';

const root = ReactDOM.createRoot(document.getElementById('root'));
AXIOS_API_INSTANCE.defaults.baseURL = API_URL;
AXIOS_API_INSTANCE.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

root.render(
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <BrowserRouter >
      <CookiesProvider>
        <DarkModeState>
          <AllLocationState>
            <UserState>
              <LocationState>
                <CustomerState>
                  <RecentOrdersState>
                    <CartState>
                      <SnackbarProvider maxSnack={3}>
                        <App />
                      </SnackbarProvider>
                    </CartState>
                  </RecentOrdersState>
                </CustomerState>
              </LocationState>
            </UserState>
          </AllLocationState>
        </DarkModeState>
      </CookiesProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
