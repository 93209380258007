import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from "@mui/material"
import { Button } from "@mui/material"
import { MuiTelInput } from "mui-tel-input"

export default function ReceiptTextModal({
    open,
    closeModal
}) {
    const [phone, setPhone] = React.useState('')

    const updatePhone = (e, extra) => {
        if (extra.nationalNumber.length < 11) {
            setPhone(e)
        }
    }

    const sendReceipt = (e) => {
        e.preventDefault();
        closeModal(phone)
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth='md'
            >
                <form onSubmit={sendReceipt}>
                    <DialogTitle>
                        {"Text Receipt To"}
                        <IconButton
                            aria-label="close"
                            onClick={() => closeModal(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <MuiTelInput
                            autoFocus
                            margin="dense"
                            id="phone"
                            label="Phone Number"
                            type="tel"
                            inputMode="numeric"
                            variant="filled"
                            value={phone}
                            onChange={updatePhone}
                            onlyCountries={['US']}
                            defaultCountry="US"
                            forceCallingCode
                            disableDropdown
                            sx={{ minWidth: '125px' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="space-between" sx={{ padding: "0px 30px 10px 30px" }}>
                            <Grid item xs="auto">
                            </Grid>
                            <Grid item xs="auto">
                                <Button variant="contained" type="submit" disabled={phone.replaceAll(' ', '').length !== 12}>
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}
