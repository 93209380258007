import { Avatar } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person';
import { stringAvatar } from '../Utils';

export default function UserAvatar({ user }) {
    return (<Avatar
        // src={user?.image_path ? `/customerimages/client/${user.client_id}/users/${user.id}/${user.image_path}` : '#'}
        sx={user?.image_path ? {} : stringAvatar(user?.name || '').sx}
    >
        {!user?.image_path && (user?.name ?
            stringAvatar(user.name || '').children
            :
            <PersonIcon />)
        }
    </Avatar>)
}