import { Box } from "@mui/material";

export default function TabPanel(props) {
    const { children, value, value_key, sx, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== value_key}
            id={`simple-tabpanel-${value_key}`}
            aria-labelledby={`simple-tab-${value_key}`}
            {...other}
        >
            {value === value_key && (
                <Box sx={{ ...{ p: 3 }, ...sx }}>
                    {children}
                </Box>
            )}
        </Box>
    );
}