import * as React from "react"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import {
    DataGrid,
} from "@mui/x-data-grid"
import { AXIOS_API_INSTANCE } from "../Constants"

import { send_authorized_request } from "../Constants/cognito"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { formatPhoneNumber, moneyFormat } from "../Utils";
import { useSnackbar } from "notistack";
import { useTrackedAllLocations } from "./UserState";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingBackdropIndeterminate } from "./LoadingBackdrop";
import { Box, Tab, Tabs } from "@mui/material"
import TabPanel from "../Utils/tabpanel"
dayjs.extend(timezone);
dayjs.extend(utc);


const dailyMoneyColumns = [
    {
        field: 'location',
        headerName: 'Location',
        flex: 1,
        editable: false,
        sortable: false,
        filterable: false
    },
    {
        field: "money_processed",
        headerName: "$ Processed*",
        flex: 1,
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: "orders",
        headerName: "# Orders",
        flex: 1,
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: "refunded",
        headerName: "# Refunded Orders",
        flex: 1,
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: "canceled",
        headerName: "# Canceled Orders",
        flex: 1,
        editable: false,
        sortable: false,
        filterable: false,
    }
]


const newCustomerColumns = [
    {
        field: 'id',
        headerName: 'Customer',
        flex: 2,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
            return (
                <Box>
                    <span>{params.value.split('|||')[0]}</span><br></br>
                    <span>{params.value.split('|||')[1]}</span>
                </Box>
            )
        }
    },
    {
        field: "orders",
        headerName: "Orders",
        flex: 1,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
            return (
                <Box>
                    {params.value.map((order) =>
                        <span>
                            <a href={`/Orders/${order.id}`}>{order.short_id}</a><br />
                        </span>
                    )}
                </Box>
            )
        }
    }
]

export default function Reports() {
    const { enqueueSnackbar } = useSnackbar();
    const [locations,] = useTrackedAllLocations();
    const [data, setData] = React.useState([])
    const [newCustomerData, setNewCustomerData] = React.useState([])
    const [tab, setTab] = React.useState("dailyMoney")
    const [isLoading, setIsLoading] = React.useState(false)
    const [searchDate, setSearchDate] = React.useState(dayjs())
    const [searchMonth, setSearchMonth] = React.useState(dayjs())

    let total_dollars = 0
    let total_orders = 0
    let total_refunded = 0
    let total_canceled = 0
    const locations_data = locations.filter((loc) => loc.id !== '-1').map((loc) => {
        const my_obj = {
            id: loc.id,
            location: loc.short_name,
            money_processed: '$0.00',
            orders: 0,
            refunded: 0,
            canceled: 0
        }
        let net_totals = 0
        data.forEach((order) => {
            if (order.location_id === loc.id) {
                if (order.paid) {
                    net_totals += parseFloat(order.net_total)
                    my_obj.orders += 1
                } else if (order.refunded) {
                    my_obj.refunded += 1
                } else if (order.canceled) {
                    my_obj.canceled += 1
                }
            }
        })
        total_dollars += net_totals
        total_orders += my_obj.orders
        total_refunded += my_obj.refunded
        total_canceled += my_obj.canceled
        my_obj.money_processed = `$${moneyFormat(net_totals, true)}`
        return my_obj
    })
    locations_data.push({
        id: '-1',
        location: 'TOTAL',
        money_processed: `$${moneyFormat(total_dollars, true)}`,
        orders: total_orders,
        refunded: total_refunded,
        canceled: total_canceled
    })


    const getOrdersForPeriod = () => {
        setIsLoading(true)
        send_authorized_request(() =>
            AXIOS_API_INSTANCE.post("reports/orders_for_period", {
                start_time: searchDate.startOf('day').unix(),
                end_time: searchDate.endOf('day').unix()
            })
                .then(
                    (orders) => {
                        console.log(orders)
                        setData(orders.data.sort((a, b) => parseInt(b.create_date) - parseInt(a.create_date)))
                        setIsLoading(false)
                    }
                ).catch((error) => {
                    setIsLoading(false)
                    console.log(error);
                    enqueueSnackbar(`Failed to load report: ${error.response?.data?.message ?? "Unknown Error Occurred"}`, { variant: 'error' })
                }))
    }

    const getNewCustomerOrdersForPeriod = () => {
        setIsLoading(true)
        send_authorized_request(() =>
            AXIOS_API_INSTANCE.post("reports/orders_for_period_with_cached_customer", {
                create_date_month_year: searchMonth.format('YYYYMM')
            })
                .then(
                    (orders) => {
                        console.log(orders)
                        let customers = {}
                        orders.data.forEach((order) => {
                            const customer = `${(order.customer_cached_name || order.transaction_data?.[0]?.cardHolder) ?? ''}|||${formatPhoneNumber(order.customer_cached_phone)}`
                            customers[customer] = (customers[customer] || []).concat(order)
                        })
                        setNewCustomerData(Object.keys(customers).map((c) => { return { 'id': c, 'orders': customers[c] } }).sort((a, b) => a.id.localeCompare(b.id)))
                        setIsLoading(false)
                    }
                ).catch((error) => {
                    setIsLoading(false)
                    console.log(error);
                    enqueueSnackbar(`Failed to load report: ${error.response?.data?.message ?? "Unknown Error Occurred"}`, { variant: 'error' })
                }))
    }

    return (
        <React.Fragment>
            <LoadingBackdropIndeterminate
                open={isLoading}
                text={"Loading Report"}
            />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <Grid container alignItems={"center"}>
                        <Grid item xs>
                            <Tabs
                                value={tab}
                                onChange={(e, value) => setTab(value)}
                                variant="scrollable"
                                TabIndicatorProps={{ sx: { backgroundColor: '#009be5' } }}
                            >
                                <Tab label="Money Per Day" value="dailyMoney" sx={{ fontWeight: "bold" }} />
                                <Tab label="Unknown Customer Info" value="newCustomerInfo" sx={{ fontWeight: "bold" }} />
                            </Tabs >
                        </Grid>
                        <Grid item xs="auto">
                        </Grid>
                    </Grid>
                </Box>
                <TabPanel value={tab} value_key={"dailyMoney"}>
                    <Grid container space={1} direction="column">
                        <Grid item xs="auto" container>
                            <Grid item xs>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={'Day Lookup'}
                                        views={['month', 'year', 'day']}
                                        value={searchDate}
                                        onChange={(newValue) => setSearchDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs>
                                <Button disabled={isLoading} color={"primary"} variant={"contained"} onClick={getOrdersForPeriod}>Generate Report</Button>
                            </Grid>
                            <Grid item xs>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            {data?.length === 0 &&
                                <h2 style={{ textAlign: 'center' }}>NO DATA</h2>
                            }
                            {data.length > 0 &&
                                <Box>
                                    <DataGrid
                                        rows={locations_data ?? []}
                                        columns={dailyMoneyColumns}
                                        autoHeight={true}
                                        hideFooter
                                        disableColumnMenu
                                        disableColumnSelector
                                        disableColumnFilter
                                    />
                                    <Box sx={{ pt: 1 }}>
                                        <span>* Does not include orders that were canceled, refunded, or still in payment pending status</span>
                                    </Box>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tab} value_key={"newCustomerInfo"}>
                    <Grid container space={1} direction="column">
                        <Grid item xs="auto" container>
                            <Grid item xs>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={'Month Lookup'}
                                        views={['month', 'year']}
                                        value={searchMonth}
                                        onChange={(newValue) => setSearchMonth(newValue)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs>
                                <Button disabled={isLoading} color={"primary"} variant={"contained"} onClick={getNewCustomerOrdersForPeriod}>Generate Report</Button>
                            </Grid>
                            <Grid item xs>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            {newCustomerData?.length === 0 &&
                                <h2 style={{ textAlign: 'center' }}>NO DATA</h2>
                            }
                            {newCustomerData.length > 0 &&
                                <DataGrid
                                    rows={newCustomerData ?? []}
                                    columns={newCustomerColumns}
                                    autoHeight={true}
                                    hideFooter
                                    disableColumnMenu
                                    disableColumnSelector
                                    disableColumnFilter
                                />
                            }
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        </React.Fragment >
    );
}
