import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Box, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import {
    DataGrid,
} from "@mui/x-data-grid"
import { formatPhoneNumber } from "../Utils"

const columns = [
    {
        field: 'all',
        editable: false,
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: (params) => {
            return (
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <span>{params.row.cust_name}</span><br></br>
                    <span><i>{formatPhoneNumber(params.row.cust_phone)}</i></span>
                </Box>
            )
        }
    }
]

export default function SelectCustomerModal({
    customers,
    open,
    closeModal
}) {
    const rowClick = (params) => {
        closeModal(params.row)
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth='md'
            >
                <DialogTitle>
                    {"Select Customer"}
                    <IconButton
                        aria-label="close"
                        onClick={() => closeModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ minWidth: '300px' }}>
                    <DataGrid
                        slots={{
                            columnHeaders: () => null,
                        }}
                        rows={customers ?? []}
                        columns={columns}
                        autoHeight={true}
                        onRowClick={rowClick}
                        hideFooter
                        disableColumnMenu
                        disableColumnSelector
                        disableColumnFilter
                    />
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    )
}
