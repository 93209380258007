import * as React from 'react';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AXIOS_API_INSTANCE, LONG_DATE_FORMAT } from '../Constants';
import { Box, Chip, Grid, Menu, MenuItem, ButtonGroup, useMediaQuery, IconButton } from '@mui/material';
import { send_authorized_request } from '../Constants/cognito';
import { useSnackbar } from 'notistack';
import { formatPhoneNumber, moneyFormat } from '../Utils';
import { LoadingBackdropIndeterminate } from './LoadingBackdrop';
import { useNavigate } from 'react-router-dom';
import UserAvatar from './UserAvatar';
import PayModal from './PayModal';
import ReceiptTextModal from './ReceiptTextModal';
import ReceiptEmailModal from './ReceiptEmailModal';
import ConfirmModal from '../Utils/ConfirmModal';
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { add_recent_order, useTrackedAllLocations, useTrackedRecentOrders, useTrackedUser } from './UserState';
import { useTheme } from '@emotion/react';
dayjs.extend(timezone);
dayjs.extend(utc);

const payment_success = (<Chip icon={<CheckCircleIcon />} label="Success" color={'green'} />)
const payment_failed = (<Chip icon={<ErrorIcon />} label="Failed" color={'error'} />)
const payment_in_progress = (<Chip icon={<HourglassBottomIcon />} label="In Progress" color={'orange'} />)

export default function OrderView({ orderId }) {
    const { enqueueSnackbar } = useSnackbar();
    const [my_user,] = useTrackedUser();
    const [recentOrders, setRecentOrders] = useTrackedRecentOrders()
    const [locations,] = useTrackedAllLocations()
    const [order, setOrder] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [sendingReceipt, setSendingReceipt] = React.useState(false);
    const [refreshTimer, setRefreshTimer] = React.useState(undefined)
    const [openPaymentModal, setOpenPaymentModal] = React.useState(false)
    const [EmailReceiptMenuAnchorElement, setEmailReceiptMenuAnchorElement] = React.useState(null);
    const [EmailReceiptMenuItemOpen, setEmailReceiptMenuItemOpen] = React.useState(false);
    const [EmailReceiptModalOpen, setEmailReceiptModalOpen] = React.useState(false);
    const [TextReceiptMenuAnchorElement, setTextReceiptMenuAnchorElement] = React.useState(null);
    const [TextReceiptMenuItemOpen, setTextReceiptMenuItemOpen] = React.useState(false);
    const [TextReceiptModalOpen, setTextReceiptModalOpen] = React.useState(false);
    const [confirmation, setConfirmation] = React.useState({
        open: false,
        text: '',
        callback: null
    })
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const setSelectedItem = () => { }

    const sendEmailReceipt = (custom_email) => {
        setEmailReceiptMenuItemOpen(false)
        setSendingReceipt(true)
        const body = {}
        if (custom_email) {
            body['custom_email'] = custom_email
        }
        send_authorized_request(() => AXIOS_API_INSTANCE.post(`orders/${orderId}/email_receipt`, body).then((order) => {
            console.log(order);
            enqueueSnackbar('Successfully emailed receipt', { variant: 'success' })
            setSendingReceipt(false)
        }).catch((error) => {
            console.log(error);
            enqueueSnackbar(`Failed to send receipt: ${error.response?.data?.message ?? "Unknown Error Occurred"}`, { variant: 'error' })
            setSendingReceipt(false)
        }))
    }

    const sendTextReceipt = (custom_phone) => {
        setTextReceiptMenuItemOpen(false)
        setSendingReceipt(true)
        const body = {}
        if (custom_phone) {
            body['custom_phone'] = custom_phone
        }
        send_authorized_request(() => AXIOS_API_INSTANCE.post(`orders/${orderId}/text_receipt`, body).then((order) => {
            console.log(order);
            enqueueSnackbar('Successfully texted receipt', { variant: 'success' })
            setSendingReceipt(false)
        }).catch((error) => {
            console.log(error);
            enqueueSnackbar(`Failed to send receipt: ${error.response?.data?.message ?? "Unknown Error Occurred"}`, { variant: 'error' })
            setSendingReceipt(false)
        }))
    }

    const clickEmailReceipt = (receipt) => () => { sendEmailReceipt(receipt) }
    const clickCustomEmailReceipt = () => { setEmailReceiptModalOpen(true) }
    const openEmailReceiptOptionsMenu = (event) => {
        setEmailReceiptMenuAnchorElement(event.currentTarget)
        setEmailReceiptMenuItemOpen(true)
    }
    const clickTextReceipt = (receipt) => () => { sendTextReceipt(receipt) }
    const clickCustomTextReceipt = () => { setTextReceiptModalOpen(true) }
    const openTextReceiptOptionsMenu = (event) => {
        setTextReceiptMenuAnchorElement(event.currentTarget)
        setTextReceiptMenuItemOpen(true)
    }

    const closePayModal = (changes) => {
        setOpenPaymentModal(false)
        if (changes) {
            getOrder()
        }
    }

    const refund_transaction = (payment_index) => {
        const text = `Are you sure you want to grant a refund?`
        setConfirmation({
            open: true,
            text: text,
            callback: (confirmation) => {
                if (confirmation) {
                    setIsLoading(true)
                    send_authorized_request(() => AXIOS_API_INSTANCE.post(`orders/${orderId}/refund/${payment_index}`).then((order) => {
                        console.log(order);
                        setOrder(order.data)
                        add_recent_order(order.data, recentOrders, setRecentOrders)
                        setIsLoading(false)
                    }).catch((error) => {
                        console.log(error);
                        enqueueSnackbar(`Failed to refund payment: ${error.response?.data?.message ?? "Unknown Error Occurred"}`, { variant: 'error' })
                        setIsLoading(false)
                    }))
                }
                setConfirmation({ text: text, open: false })
            }
        })
    }

    const cancelOrder = () => {
        const text = `Are you sure you want to cancel this order?`
        setConfirmation({
            open: true,
            text: text,
            callback: (confirmation) => {
                if (confirmation) {
                    setIsLoading(true)
                    send_authorized_request(() => AXIOS_API_INSTANCE.post(`orders/${orderId}/cancel`).then((order) => {
                        console.log(order);
                        setOrder(order.data)
                        add_recent_order(order.data, recentOrders, setRecentOrders)
                        setIsLoading(false)
                    }).catch((error) => {
                        console.log(error);
                        enqueueSnackbar(`Failed to cancel order: ${error.response?.data?.message ?? "Unknown Error Occurred"}`, { variant: 'error' })
                        setIsLoading(false)
                    }))
                }
                setConfirmation({ text: text, open: false })
            }
        })
    }

    const getOrder = (silent = false) => {
        if (!silent) {
            setIsLoading(true)
        }
        send_authorized_request(() => AXIOS_API_INSTANCE.get(`orders/${orderId}`).then((order) => {
            console.log(order);
            setOrder(order.data)
            add_recent_order(order.data, recentOrders, setRecentOrders)
            setIsLoading(false)
        }).catch((error) => {
            console.log(error);
            enqueueSnackbar(`Failed to load order: ${error.response?.data?.message ?? "Unknown Error Occurred"}`, { variant: 'error' })
            navigate('/Orders')
        }))
    }

    React.useEffect(() => {
        if (!isLoading) {
            getOrder()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId])

    React.useEffect(() => {
        if (order?.payment_in_progress) {
            clearTimeout(refreshTimer)
            setRefreshTimer(setTimeout(() => { getOrder(true) }, 7 * 1000))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    let status_chip = undefined
    if (order?.paid) {
        status_chip = (<Chip icon={<CheckCircleIcon />} label="Paid" color={'green'} />)
    } else if (order?.payment_in_progress) {
        status_chip = (<Chip icon={<HourglassBottomIcon />} label="Payment In Progress" color={'orange'} />)
    } else if (order?.refunded) {
        status_chip = (<Chip icon={<CurrencyExchangeIcon />} label="Refunded" color={'orange'} />)
    } else if (order?.canceled) {
        status_chip = (<Chip icon={<CancelIcon />} label="Canceled" color={'grey'} />)
    } else {
        status_chip = (<Chip icon={<MoneyOffCsredIcon />} label="Payment Due" color={'error'} />)
    }

    return (
        <React.Fragment>
            <LoadingBackdropIndeterminate
                open={isLoading}
                text="Getting Order"
            />
            <LoadingBackdropIndeterminate
                open={sendingReceipt}
                text="Sending Receipt"
            />
            <Grid container direction={'column'} spacing={1} rowSpacing={0} columnSpacing={0} sx={{ pr: '6px' }} wrap='nowrap'>
                {isSmUp &&
                    <Grid item xs={1} container spacing={0} justifyContent={'space-between'}>
                        <Grid item xs={2}>
                            <Box onClick={() => navigate(window.location.pathname.split('/').slice(0, -1).join('/'))}>
                                <IconButton color="inherit">
                                    <ArrowBackIcon />
                                </IconButton>
                            </Box>
                        </Grid>

                        <Grid item xs={8} sx={{ textAlign: 'center' }}>
                            <span style={{ fontWeight: 700, fontSize: '18px' }}>Order {order?.short_id} <span style={{ whiteSpace: 'nowrap' }}>{dayjs.unix(order?.create_date).local().format(LONG_DATE_FORMAT)}</span></span><br />
                            {status_chip}
                            {/* <h3 style={{ marginBottom: 0 }}>Order {order?.short_id} <span style={{ whiteSpace: 'nowrap' }}>{dayjs.unix(order?.create_date).local().format(LONG_DATE_FORMAT)}</span></h3>
                            {status_chip} */}
                        </Grid>
                        <Grid item xs={2} textAlign={'right'}>
                            {(order?.paid || order?.refunded || order?.canceled) ?
                                <Button variant='contained' onClick={() => navigate('/Dashboard')}>New Order</Button>
                                :
                                <Button variant='contained' disabled={order?.payment_in_progress} color='error' onClick={() => cancelOrder()}>Cancel Order</Button>
                            }
                        </Grid>
                    </Grid>
                }
                {!isSmUp &&
                    <Grid item xs={1} container spacing={0} justifyContent={'space-between'}>
                        <Grid item xs={6}>
                            <Box onClick={() => navigate(window.location.pathname.split('/').slice(0, -1).join('/'))}>
                                <IconButton color="inherit">
                                    <ArrowBackIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={6} textAlign={'right'}>
                            {(order?.paid || order?.refunded || order?.canceled) ?
                                <Button variant='contained' onClick={() => navigate('/Dashboard')}>New Order</Button>
                                :
                                <Button variant='contained' disabled={order?.payment_in_progress} color='error' onClick={() => cancelOrder()}>Cancel Order</Button>
                            }
                        </Grid>
                    </Grid>
                }
                {!isSmUp &&
                    <Grid item xs={1} sx={{ textAlign: 'center' }}>
                        <span style={{ fontWeight: 700, fontSize: '18px' }}>Order {order?.short_id} <span style={{ whiteSpace: 'nowrap' }}>{dayjs.unix(order?.create_date).local().format(LONG_DATE_FORMAT)}</span></span><br />
                        {status_chip}
                        {/* <h3 style={{ marginBottom: 0 }}>Order {order?.short_id} <span style={{ whiteSpace: 'nowrap' }}>{dayjs.unix(order?.create_date).local().format(LONG_DATE_FORMAT)}</span></h3>
                            {status_chip} */}
                    </Grid>
                }
                <Grid item xs={1}>
                    <Grid container spacing={3} justifyContent={"center"} alignItems={"center"}>
                        <Grid item>
                            <ButtonGroup color='primary' variant='contained'>
                                <Button disabled={order?.payment_in_progress || !order?.paid} onClick={order?.customer?.cust_email ? clickEmailReceipt(order?.customer?.cust_email) : clickCustomEmailReceipt}>Email Receipt{order?.customer?.cust_email ? ` ${order?.customer?.cust_email}` : ''}</Button>
                                <Button disabled={order?.payment_in_progress || !order?.paid} sx={{ p: '6px' }} onClick={openEmailReceiptOptionsMenu}><ArrowDropDownIcon /></Button>
                            </ButtonGroup>
                            <Menu
                                id={'emailreceiptoptionsmenu'}
                                anchorEl={EmailReceiptMenuAnchorElement}
                                open={EmailReceiptMenuItemOpen}
                                onClose={() => setEmailReceiptMenuItemOpen(false)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                {(order?.customer?.customerExtraEmails || []).map((email) =>
                                    <MenuItem onClick={clickEmailReceipt(email)}>Email Receipt {email}</MenuItem>
                                )
                                }
                                <MenuItem onClick={clickCustomEmailReceipt}>Send To New Email</MenuItem>
                            </Menu>
                        </Grid>
                        <Grid item>
                            <ButtonGroup color='primary' variant='contained'>
                                {order?.customer?.cust_phone &&
                                    <Button disabled={order?.payment_in_progress || !order?.paid} onClick={clickTextReceipt(order?.customer?.cust_phone)}>Text Receipt{` ${formatPhoneNumber(order?.customer?.cust_phone)}`}</Button>
                                }
                                {!order?.customer?.cust_phone && order?.customer_cached_phone &&
                                    <Button disabled={order?.payment_in_progress || !order?.paid} onClick={clickTextReceipt(order?.customer_cached_phone)}>Text Receipt{` ${formatPhoneNumber(order?.customer_cached_phone)}`}</Button>
                                }
                                {!order?.customer?.cust_phone && !order?.customer_cached_phone &&
                                    <Button disabled={order?.payment_in_progress || !order?.paid} onClick={clickCustomTextReceipt}>Text Receipt</Button>
                                }
                                <Button disabled={order?.payment_in_progress || !order?.paid} sx={{ p: '6px' }} onClick={openTextReceiptOptionsMenu}><ArrowDropDownIcon /></Button>
                            </ButtonGroup>
                            <Menu
                                id={'textreceiptoptionsmenu'}
                                anchorEl={TextReceiptMenuAnchorElement}
                                open={TextReceiptMenuItemOpen}
                                onClose={() => setTextReceiptMenuItemOpen(false)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={clickCustomTextReceipt}>Text To New Number</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <Grid container sx={{ justifyContent: { xs: 'center', sm: 'space-between' }, textAlign: { xs: 'center', sm: 'inherit' } }} columns={{ xs: 4, sm: 12 }}>
                        <Grid item xs={4}>
                            {!order?.customer && !(order?.customer_cached_name || order?.customer_cached_phone) &&
                                <Box sx={{ paddingBottom: 2 }}>
                                    <span><b>No Customer Selected</b></span>
                                </Box>
                            }
                            {!order?.customer && (order?.customer_cached_name || order?.customer_cached_phone) &&
                                <Box sx={{ paddingBottom: 2 }}>
                                    <span><b><i>CACHED</i> Customer:</b></span><br />
                                    <span style={{ marginLeft: 16 }}>{order?.customer_cached_name}</span><br />
                                    <span style={{ marginLeft: 16 }}>{formatPhoneNumber(order?.customer_cached_phone)}</span>
                                </Box>
                            }
                            {order?.customer &&
                                <Box sx={{ paddingBottom: 2 }}>
                                    <span><b>Customer:</b></span><br />
                                    <span style={{ marginLeft: 16 }}>{order?.customer.cust_name}</span><br />
                                    <span style={{ marginLeft: 16 }}>{formatPhoneNumber(order?.customer.cust_phone)}</span>
                                </Box>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ paddingBottom: 2 }}>
                                <span><b>Location:</b></span><br />
                                <span>{locations.find((x) => x.id === order.location_id)?.short_name}</span>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ paddingBottom: 2, textAlign: { xs: '-webkit-center', sm: 'inherit' } }}>
                                <span><b>Employee:</b></span><br />
                                <Grid container spacing={1} sx={{ width: { xs: 'fit-content', sm: 'inherit' } }}>
                                    <Grid item xs="auto">
                                        <span><UserAvatar user={order.user} /></span>
                                    </Grid>
                                    <Grid item xs>
                                        <span style={{ 'fontSize': '20px', whiteSpace: 'nowrap' }}>{order.user?.name}</span>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <Grid container columns={9} alignItems={"center"}>
                        <Grid item xs={4}>
                            <span><b>Item</b></span>
                        </Grid>
                        <Grid item xs={2}>
                            <span><b>Qty</b></span>
                        </Grid>
                        <Grid item xs={3} textAlign={'right'}>
                            <span><b>Cost</b></span>
                        </Grid>
                    </Grid>
                    {(order?.items || []).map((item) =>
                        <Box key={item.id}>
                            <Grid container columns={9} alignItems={"center"}>
                                <Grid item xs={4} onClick={() => setSelectedItem(item)}>
                                    <span>{item.name}</span>
                                </Grid>
                                <Grid item xs={2} onClick={() => setSelectedItem(item)}>
                                    <span>{moneyFormat(item.quantity, false)} {item.quantity_of_measure}</span>
                                </Grid>
                                <Grid item xs={3} onClick={() => setSelectedItem(item)} textAlign={'right'}>
                                    <span>${moneyFormat(Math.round(item.price_per_item * parseFloat(item.quantity) * 100) / 100)}</span>
                                </Grid>
                            </Grid>
                            <hr />
                        </Box>
                    )}
                    <Grid container direction={'row-reverse'} columns={{ xs: 6, sm: 12 }}>
                        <Grid item xs={6} container justifyContent={"flex-end"} sx={{ textAlign: 'right' }}>
                            <Grid item xs={9}>
                                <span>Total Before Tax:</span>
                            </Grid>
                            <Grid item xs={3}>
                                <span>${moneyFormat(order?.total_cost)}</span>
                            </Grid>
                            <Grid item xs={9}>
                                <span>Tax:</span>
                            </Grid>
                            <Grid item xs={3}>
                                <span>${moneyFormat(order?.total_tax)}</span>
                            </Grid>
                            <Grid item xs={9}>
                                <span>Total:</span>
                            </Grid>
                            <Grid item xs={3}>
                                <span>${moneyFormat(order?.net_total)}</span>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <span><b>Paladin Invoice ID:</b></span><br />
                            <span>{order.paladin_invoice_id ? order.paladin_invoice_id : "NOT SYNCED"}</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <Grid container columns={9} alignItems={"center"}>
                        <Grid item xs={2}>
                            <span><b>Payment</b></span>
                        </Grid>
                        <Grid item xs={2}>
                            <span><b>Status</b></span>
                        </Grid>
                        <Grid item xs={5} textAlign={'right'}>
                            <span><b>Employee</b></span>
                        </Grid>
                    </Grid>
                    {order?.payment_in_progress &&
                        <Box>
                            <Grid container columns={9} alignItems={"center"}>
                                <Grid item xs={2}>
                                    <span>${moneyFormat(order?.net_total)}</span>
                                </Grid>
                                <Grid item xs={3} >
                                    {payment_in_progress}
                                </Grid>
                                <Grid item xs={4} >
                                    <Grid container space={0} justifyContent={'flex-end'}>
                                        <Grid item xs="auto" sx={{ pr: 2, pl: 2 }}>
                                            <span><UserAvatar user={undefined} /></span>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <span style={{ 'fontSize': '20px' }}></span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <hr />
                        </Box>
                    }
                    {(order?.transaction_data || []).map((transaction, index) =>
                        <Box key={index}>
                            <Grid container columns={9} alignItems={"center"}>
                                <Grid item xs={2}>
                                    <span>${['void', 'refund'].includes(transaction.transactionType) ? '-' : ''}{moneyFormat(transaction.authorizedAmount)}</span>
                                    {!['void', 'refund'].includes(transaction.transactionType) &&
                                        !transaction.REFUNDED &&
                                        order?.paid && !order?.payment_in_progress &&
                                        !(transaction.error || parseFloat(transaction.authorizedAmount) === 0.0 || !transaction.approved) &&
                                        <Button disabled={!(my_user.permissions_refund_admin || my_user.root_admin)} variant='outlined' color='error' onClick={() => refund_transaction(index)} sx={{ ml: 1 }}>
                                            Refund
                                        </Button>
                                    }
                                </Grid>
                                <Grid item xs={3}>
                                    {(transaction.error || parseFloat(transaction.authorizedAmount) === 0.0 || !transaction.approved) ?
                                        isSmUp ? <span>{payment_failed} ({transaction.responseDescription || transaction.error})</span> : payment_failed
                                        :
                                        payment_success}
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container space={0} justifyContent={'flex-end'}>
                                        <Grid item xs="auto" sx={{ pr: 2, pl: 2 }}>
                                            <span><UserAvatar user={transaction.user} /></span>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <span style={{ 'fontSize': '20px' }}>{transaction.user?.name}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <hr />
                        </Box>
                    )}
                </Grid>
                {!order?.paid && !order?.refunded && !order?.canceled &&
                    <Grid item xs sx={{ textAlign: 'center' }}>
                        <Button sx={{ width: '100%', maxWidth: '370px', margin: '0 2 0 2', paddingTop: 1, paddingBottom: 1, fontSize: 20 }} variant="contained" disabled={order?.payment_in_progress} onClick={() => setOpenPaymentModal(true)}>Pay</Button>
                    </Grid>
                }
            </Grid>
            {openPaymentModal &&
                <PayModal
                    order={order}
                    new_order={false}
                    customer={order?.customer}
                    open={openPaymentModal}
                    closeModal={closePayModal}
                />
            }
            <ReceiptTextModal
                open={TextReceiptModalOpen}
                closeModal={(result) => {
                    setTextReceiptModalOpen(false)
                    if (result) {
                        setTextReceiptMenuItemOpen(false)
                        sendTextReceipt(result.substring(3).replaceAll(' ', ''))
                    }
                }}
            />
            <ReceiptEmailModal
                open={EmailReceiptModalOpen}
                closeModal={(result) => {
                    setEmailReceiptModalOpen(false)
                    if (result) {
                        setEmailReceiptMenuItemOpen(false)
                        sendEmailReceipt(result)
                    }
                }}
            />
            <ConfirmModal
                {...confirmation}
            />

        </React.Fragment>
    );
}