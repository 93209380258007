
Object.defineProperty(exports, "__esModule", { value: true });
export const ceil = (option, dayjsClass) => {
    dayjsClass.prototype.ceil = function (unit, amount) {
        return this.add(amount - (this.get(unit) % amount), unit).startOf(unit);
    };
};

export const stringToColor = (string) => {
    let hash = 0;
    for (let char in string) {
        hash = string.charCodeAt(char) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i += 1) {
        color += `00${((hash >> (i * 8)) & 0xff).toString(16)}`.slice(-2);
    }
    return color;
}

export const stringAvatar = (name) => {
    let children = ''
    try {
        children = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    } catch (error) {
        if (name.length >= 0) {
            children = `${name[0]}`
        }
    }
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: children,
    };
}

export const getItemPricing = (item, customer, location) => {
    const pricingPlan = customer?.PricingPlan || 'Retail'
    const price = item.pricing[location.id]?.[pricingPlan]
    if(!price || parseFloat(price) === 0.0) {
        return item.pricing[location.id]?.['Retail']
    }else {
        return price
    }
}

export const moneyFormat = (num, includeZeros = true) => {
    num = num ?? 0
    var parts = parseFloat(num).toFixed(2).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (parts[1] === '00' && !includeZeros) {
        return parts[0]
    } else {
        return parts.join(".");
    }
}
export const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
}