import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { IconButton, InputLabel } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "@mui/material"
import { formatPhoneNumber, moneyFormat, getItemPricing } from "../Utils"
import { Box } from "@mui/material"
import Select from "@mui/material/Select"
import { useCookies } from 'react-cookie';
import { add_recent_order, useTrackedLocation, useTrackedRecentOrders } from "./UserState"
import { MenuItem } from "@mui/material"
import { send_authorized_request } from "../Constants/cognito"
import { AXIOS_API_INSTANCE } from "../Constants"
import { useSnackbar } from 'notistack';
import { LoadingBackdropIndeterminate } from "./LoadingBackdrop"
import { FormControl } from "@mui/material"
import { useNavigate } from "react-router-dom"

export default function PayModal({
    new_order,
    order,
    cart,
    customer,
    open,
    closeModal
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [cookies, setCookie] = useCookies()
    const [location,] = useTrackedLocation()
    const [recentOrders, setRecentOrders] = useTrackedRecentOrders()
    const [isLoading, setIsLoading] = React.useState(false)
    const navigate = useNavigate();

    const sendOrder = () => {
        setIsLoading(true)
        if (new_order) {
            const my_order = {
                location_id: location.id,
                device_name: cookies.locations?.[location.id]?.selected_device,
                items: cart.cart
            }
            if (customer && customer.id !== -1) {
                my_order.customer_id = customer.id
            } else if (customer && customer.id === -1) {
                my_order.customer_cached_name = customer.cust_name
                my_order.customer_cached_phone = customer.cust_phone
            }
            send_authorized_request(() => AXIOS_API_INSTANCE.post(`orders`, my_order).then((order) => {
                enqueueSnackbar('Successfully submitted order', { variant: 'success' })
                closeModal(true)
                setIsLoading(false)
                add_recent_order(order.data, recentOrders, setRecentOrders)
                navigate(`/Orders/${order.data.id}`)
            }).catch((error) => {
                setIsLoading(false)
                enqueueSnackbar(`Failed to submit order <${error.response?.data?.message ?? "Unknown Error Occurred"}>`, { variant: 'error' })
            }))
        } else {
            const my_order = {
                location_id: location.id,
                device_name: cookies.locations?.[location.id]?.selected_device
            }
            send_authorized_request(() => AXIOS_API_INSTANCE.post(`orders/${order.id}/payment`, my_order).then((order) => {
                enqueueSnackbar('Successfully submitted payment', { variant: 'success' })
                closeModal(true)
                setIsLoading(false)
            }).catch((error) => {
                setIsLoading(false)
                enqueueSnackbar(`Failed to submit payment <${error.response?.data?.message ?? "Unknown Error Occurred"}>`, { variant: 'error' })
            }))
        }
    }

    let total_cost = 0;
    let total_tax = 0;
    if (new_order) {
        total_cost = cart.cart.length === 0 ? 0 : cart.cart.reduce((accumulator, item) => accumulator + Math.round(parseFloat(getItemPricing(item, customer, location) * item.quantity) * 100) / 100, 0)
        total_tax = Math.round(total_cost * location.tax_rate) / 100
    } else {
        total_cost = parseFloat(order.total_cost)
        total_tax = parseFloat(order.total_tax)
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth='md'
            >
                <LoadingBackdropIndeterminate
                    open={isLoading}
                    text={new_order ? "Submitting Order" : "Submitting Payment"}
                />
                <DialogTitle>
                    {"Pay"}
                    <IconButton
                        aria-label="close"
                        onClick={() => closeModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ minWidth: '300px' }}>
                    {order !== undefined &&
                        <Box sx={{ paddingBottom: 2 }}>
                            <span><b>Order:</b></span><br />
                            <span style={{ marginLeft: 16 }}>{order.short_id}</span>
                        </Box>
                    }
                    {!customer &&
                        <h4>No Customer Selected</h4>
                    }
                    {customer && customer.id === -1 &&
                        <Box sx={{ paddingBottom: 2 }}>
                            <span><b><i>CACHING</i> Customer:</b></span><br />
                            <span style={{ marginLeft: 16 }}>{customer.cust_name}</span><br />
                            <span style={{ marginLeft: 16 }}>{formatPhoneNumber(customer.cust_phone)}</span>
                        </Box>
                    }
                    {customer && customer.id !== -1 &&
                        <Box sx={{ paddingBottom: 2 }}>
                            <span><b>Customer:</b></span><br />
                            <span style={{ marginLeft: 16 }}>{customer.cust_name}</span><br />
                            <span style={{ marginLeft: 16 }}>{formatPhoneNumber(customer.cust_phone)}</span>
                        </Box>
                    }
                    <h4>Total: ${moneyFormat(total_cost + total_tax)}</h4>
                    {location.id === 'all' ?
                        <Box width={'100%'} sx={{ textAlign: 'center' }}>
                            <span style={{ color: 'red' }}>Please Select Location</span>
                        </Box>
                        :
                        <FormControl fullWidth>
                            <InputLabel id="payment-device-select-label">Payment Device</InputLabel>
                            <Select
                                labelId="payment-device-select-label"
                                id="payment-device-select"
                                value={cookies.locations?.[location.id]?.selected_device}
                                label="Payment Device"
                                onChange={(event) => {
                                    setCookie(
                                        "locations",
                                        { ...(cookies.locations || {}), [location.id]: { selected_device: event.target.value } },
                                        { path: "/" }
                                    )
                                }}
                            >
                                {(location?.device_names || []).map((device, index) =>
                                    <MenuItem key={index} value={device}>{device}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={location.id === 'all' || cookies.locations?.[location.id]?.selected_device === undefined} variant="contained" autoFocus onClick={() => sendOrder()} sx={{ width: '100%', paddingLeft: '1', paddingRight: '1', marginRight: 2, marginLeft: 2 }}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
