import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { IconButton, TextField } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from "@mui/material"
import { Button } from "@mui/material"

const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

export default function ReceiptEmailModal({
    open,
    closeModal
}) {
    const [email, setEmail] = React.useState('')

    const sendReceipt = (e) => {
        e.preventDefault();
        closeModal(email)
    }

    const valid_email = Boolean(email.match(emailRegex))

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth='md'
            >
                <form onSubmit={sendReceipt}>
                    <DialogTitle>
                        {"Email Receipt To"}
                        <IconButton
                            aria-label="close"
                            onClick={() => closeModal(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            label="Email"
                            type="email"
                            variant="filled"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ minWidth: '250px' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="space-between" sx={{ padding: "0px 30px 10px 30px" }}>
                            <Grid item xs="auto">
                            </Grid>
                            <Grid item xs="auto">
                                <Button variant="contained" type="submit" disabled={!valid_email}>
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}
