import * as React from "react"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import {
    DataGrid,
} from "@mui/x-data-grid"
import { AXIOS_API_INSTANCE } from "../Constants"

import { useCookies } from "react-cookie"
import { send_authorized_request } from "../Constants/cognito"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import ItemModal from "./ItemModal";
dayjs.extend(timezone);
dayjs.extend(utc);


const columns = [
    {
        field: 'type',
        headerName: 'Type',
        width: 200,
        editable: false,
        sortable: true,
        filterable: true
    },
    {
        field: "name",
        headerName: "Name",
        width: 250,
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: "sku",
        headerName: "SKU",
        width: 150,
        editable: false,
        sortable: true,
        filterable: true,
    },
]

export default function Items(props) {
    const [cookies, setCookie] = useCookies(["pagination"])
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: cookies.pagination?.items_pageSize || 25,
        page: 0
    });
    const [items, setItems] = React.useState([])
    const [selectedItem, setSelectedItem] = React.useState(undefined)
    const [createNewItem, setCreateNewItem] = React.useState(undefined)
    const [isLoading, setIsLoading] = React.useState(true)

    const userRowClick = (params) => {
        if (!params.row.paid && !params.row.payment_in_progress) {
            setSelectedItem(params.row)
        }
    }
    const closeItemModal = (changes) => {
        setSelectedItem(undefined)
        setCreateNewItem(false)
        if (changes) {
            getItems()
        }
    }

    const createItemClick = () => {
        setSelectedItem({
            name: '',
            type: '',
            quantity_of_measure: 'Each',
            sku: '',
            pricing: {}
        })
        setCreateNewItem(true)
    }

    const setMyPaginationModel = (model) => {
        if (!isLoading) {
            setCookie(
                "pagination",
                { ...cookies.pagination, items_pageSize: model.pageSize },
                { path: "/" }
            )
            setPaginationModel(model)
        }
    }

    const getItems = () => {
        setIsLoading(true)
        send_authorized_request(() =>
            AXIOS_API_INSTANCE.get("items")
                .then((items) => {
                    console.log(items)
                    const sorted_items = items.data.sort(
                        (a, b) => {
                            if (a.type !== b.type) {
                                return (a.type || '').localeCompare(b.type)
                            } else {
                                return (a.name || '').localeCompare(b.name)
                            }
                        }
                    )
                    setItems(sorted_items)
                    setIsLoading(false)
                })
        )
    }


    React.useEffect(() => {
        getItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs>
                    <Button sx={{ mb: 1 }} onClick={createItemClick} variant="contained" color="primary"><AddCircleSharpIcon />  Add Item</Button>
                    <DataGrid
                        density="compact"
                        rows={items}
                        rowCount={items.length}
                        columns={columns}
                        disableRowSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
                        autoHeight={true}
                        loading={isLoading}
                        pageSizeOptions={[10, 25, 50, 100]}
                        pagination
                        paginationMode="client"
                        paginationModel={paginationModel}
                        onPaginationModelChange={setMyPaginationModel}
                        hideFooterSelectedRowCount
                        onRowClick={userRowClick}
                    />
                </Grid>
            </Grid>
            <ItemModal
                item={selectedItem}
                open={selectedItem !== undefined}
                closeModal={closeItemModal}
                creatingNewItem={createNewItem}
            />
        </React.Fragment>
    );
}
