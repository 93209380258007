import * as React from "react"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import { AXIOS_API_INSTANCE } from "../Constants"

import { send_authorized_request } from "../Constants/cognito"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Box, TextField } from "@mui/material";
import { LoadingBackdropIndeterminate } from "./LoadingBackdrop";
import { useSnackbar } from "notistack"
dayjs.extend(timezone);
dayjs.extend(utc);


export default function Config(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [config, setConfig] = React.useState({})
    const [isLoading, setIsLoading] = React.useState(true)
    const [isSaving, setIsSaving] = React.useState(false)

    const getConfig = () => {
        setIsLoading(true)
        send_authorized_request(() =>
            AXIOS_API_INSTANCE.get("config")
                .then((config) => {
                    console.log(config)
                    setConfig(config.data)
                    setIsLoading(false)
                })
        )
    }

    const saveConfig = (e) => {
        e.preventDefault()
        setIsSaving(true)
        send_authorized_request(() =>
            AXIOS_API_INSTANCE.post("config", config)
                .then((config) => {
                    console.log(config)
                    setIsSaving(false)
                }).catch((error) => {
                    setIsSaving(false)
                    enqueueSnackbar(`Failed to save config <${error.response?.data?.message ?? "Unknown Error Occurred"}>`, { variant: 'error' })
                }))
    }


    React.useEffect(() => {
        getConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <LoadingBackdropIndeterminate
                open={isLoading}
                text="Loading Config"
            />
            <LoadingBackdropIndeterminate
                open={isSaving}
                text="Saving Config"
            />
            <Box sx={{ margin: 'auto', mt: 1, minWidth: '300px', width: '65%' }}>
                <form onSubmit={(e) => saveConfig(e)}>
                    <Grid container spacing={3} direction="column" alignItems="center" width={'100%'}>
                        <Grid item xs width={'100%'}>
                            <TextField
                                margin="dense"
                                id="currentCatchPhrase"
                                label="Catch Phrase"
                                type="text"
                                variant="filled"
                                disabled={isLoading}
                                value={config?.currentCatchPhrase || ''}
                                onChange={(e) => setConfig({ ...config, currentCatchPhrase: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs width={'100%'}>
                            <TextField
                                margin="dense"
                                id="currentSeasonMessage"
                                label="Season Message"
                                type="text"
                                variant="filled"
                                disabled={isLoading}
                                value={config?.currentSeasonMessage || ''}
                                onChange={(e) => setConfig({ ...config, currentSeasonMessage: e.target.value })}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs width={'100%'}>
                            <TextField
                                margin="dense"
                                id="currentSurveyMessage"
                                label="Survey Message"
                                type="text"
                                variant="filled"
                                disabled={isLoading}
                                value={config?.currentSurveyMessage || ''}
                                onChange={(e) => setConfig({ ...config, currentSurveyMessage: e.target.value })}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs width={'100%'}>
                            <TextField
                                margin="dense"
                                id="surveyUrl"
                                label="Survey URL"
                                type="text"
                                variant="filled"
                                disabled={isLoading}
                                value={config?.surveyUrl || ''}
                                onChange={(e) => setConfig({ ...config, surveyUrl: e.target.value })}
                                fullWidth
                                helperText="Full URL Including the https://"
                            />
                        </Grid>
                        <Grid item>
                            <Button variant={"contained"} type="submit" disabled={isLoading} ><b>Save</b></Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment >
    );
}
