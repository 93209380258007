import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { IconButton, TextField } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from "@mui/material"
import { Button } from "@mui/material"
import { find_item } from "./CheckoutState"

export default function QuantityModal({
    cart,
    item,
    open,
    closeModal
}) {
    const [quantity, setQuantity] = React.useState(0)
    const [cacheItem, setCacheItem] = React.useState(item)

    const submitQuantity = (e) => {
        e.preventDefault();
        closeModal(parseFloat(quantity))
    }

    const updateQuantity = (e) => {
        if (e.target.value.match(/^\d+(\.\d{0,3})?$/)) {
            if (e.target.value.match(/^\d+\.$/)) {
                setQuantity(e.target.value)
            } else {
                setQuantity(parseFloat(e.target.value))
            }
        }
    }

    React.useEffect(() => {
        if (item !== undefined) {
            setQuantity(find_item(cart, item?.id)?.quantity ?? 0)
        }
        setCacheItem(item)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    return (
        <div>
            <Dialog
                open={open && item !== undefined}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth='md'
            >
                <form onSubmit={submitQuantity}>
                    <DialogTitle sx={{ fontSize: '35px' }}>
                        {"Select Quantity"}
                        <IconButton
                            aria-label="close"
                            onClick={() => closeModal(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <span style={{ fontSize: '30px' }}>{cacheItem?.name}</span>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="quantity"
                            label={item?.quantity_of_measure}
                            type="text"
                            inputProps={{ inputMode: 'decimal' }}
                            fullWidth
                            variant="outlined"
                            value={quantity}
                            onChange={updateQuantity}
                            sx={{ minWidth: '125px' }}
                            InputProps={
                                {
                                    sx: { fontSize: '30px' }
                                }
                            }
                            InputLabelProps={{
                                sx: {
                                    fontSize: "28px",
                                    "&.MuiOutlinedInput-notchedOutline": { fontSize: "28px" }
                                }
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="space-between" sx={{ padding: "0px 30px 10px 30px" }}>
                            <Grid item xs="auto">
                            </Grid>
                            <Grid item xs="auto">
                                <Button variant="contained" type="submit" disabled={!(parseFloat(quantity) > 0)}>
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}
