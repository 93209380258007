import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Badge, Box, Typography, useMediaQuery } from '@mui/material';
import { useTrackedCart } from './CheckoutState';
import { useTheme } from '@emotion/react';

const CURRENT_TAB_FRIENDLY_NAMES = {
    '/dashboard': 'Dashboard',
    '/order': 'Order',
    '/orders': 'Orders',
    '/orders/:id': 'View Order',
    '/recentorders': 'Recent Orders',
    '/login': 'Login',
    '/users': 'Users',
    '/customers': 'Customers',
    '/reports': 'Reports',
    '/items': 'Items',
    '/config': 'Config',
}

function Header({ onDrawerToggle, currenttab }) {
    const [cart, setCart] = useTrackedCart()
    const [cartSize, setCartSize] = React.useState(0)
    const [cartOpen, setCartOpen] = React.useState(0)
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    console.log(currenttab)

    if (cart.cart.length !== cartSize) {
        setCartSize(cart.cart.length)
    }
    if (cart.open !== cartOpen) {
        setCartOpen(cart.open)
    }

    return (
        <React.Fragment>
            <AppBar position="sticky" elevation={0} sx={{ padding: '8px 0px 8px 0px', backgroundColor: '#84C243' }}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid sx={{ display: { md: 'none', sm: 'block', xs: 'block' } }} item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onDrawerToggle}
                                edge="start"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs>
                            <Typography
                                color="inherit"
                                variant="h5"
                                component="h1"
                            >
                                {CURRENT_TAB_FRIENDLY_NAMES[currenttab] ?? 'Dashboard'}
                            </Typography>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            {currenttab === '/order' && isLgUp &&
                                <Grid item xs="auto" sx={{ pr: 1, pl: 1 }}>
                                    <Box onClick={() => setCart({ ...cart, open: !cartOpen })}>
                                        <IconButton color="inherit">
                                            <Badge badgeContent={cartSize} color="error">
                                                <AddShoppingCartIcon />
                                            </Badge>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

Header.propTypes = {
    onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;