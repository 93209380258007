import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { AXIOS_API_INSTANCE } from '.';
const poolData = {
    UserPoolId: 'us-east-2_jT9o5sUxi',
    ClientId: '24b9eban2nvemdsbtu5m5e717f',
    storage: window.localStorage
};

const find_idtoken = () => {
    const user = cognitoUserPool.getCurrentUser()
    let token = undefined
    if (user !== null && user.storage !== undefined) {
        for (const [key, value] of Object.entries(user.storage)) {
            if (key.endsWith('.idToken')) {
                token = value
                break
            }
        }
    }
    return token;
}

export var cognitoUserPool = new CognitoUserPool(poolData);
console.log(cognitoUserPool)
export const send_authorized_request = (callback) => {
    let user = cognitoUserPool.getCurrentUser();
    if (user != null) {
        user.getSession((err, session) => {
            if (err) {
                logout()
                return;
            }
            user.setSignInUserSession(session)
            AXIOS_API_INSTANCE.defaults.headers.common['Authorization'] = 'Bearer ' + find_idtoken();
            callback()
        })
    } else {
        logout();
        return
    }
}
export const foundUser = () => {
    console.log('foundUser function')
    console.log(cognitoUserPool.getCurrentUser())
    return Boolean(cognitoUserPool.getCurrentUser())
}
export const isLoggedIn = (setLogin, current_login, set_my_user, get_my_user, set_locations, setSelectedLocation, cookies, setCookie, enqueueSnackbar, mfaStatus, setMfaStatus) => {
    let user = cognitoUserPool.getCurrentUser();
    if (user != null) {
        user.getSession(function (err, session) {
            if (err) {
                alert(err.message || JSON.stringify(err));
                user.signOut()
                setLogin(false)
                set_my_user({})
                return;
            }
            user.setSignInUserSession(session)
            if(mfaStatus === undefined) {
                setMfaStatus(false) // Done to cut down on excess cognito requests
                user.getUserData((err, data) => {
                    if (err) {
                        alert(err.message || JSON.stringify(err));
                        return;
                    }
                    const { PreferredMfaSetting } = data;
                    setMfaStatus(PreferredMfaSetting === "SOFTWARE_TOKEN_MFA")
                });
            }
            if (!current_login) { // TODO: Should we just always do this?
                setLogin(true)
                get_my_user(set_my_user, set_locations, setSelectedLocation, cookies, setCookie, enqueueSnackbar)
            }
        })
    } else {
        if (current_login) {
            setLogin(false)
            set_my_user({})
        }
    }
}
export const handleLogin = (username, password) => {
    var authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password
    });
    var userData = {
        Username: authenticationDetails.getUsername(),
        Pool: cognitoUserPool,
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
            console.log(result)
            //var accessToken = result.getAccessToken().getJwtToken();

            //POTENTIAL: Region needs to be set if not already set previously elsewhere.
            // AWS.config.region = 'us-east-2';

            // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            // 	IdentityPoolId: '...', // your identity pool id here
            // 	Logins: {
            // 		// Change the key below according to the specific region your user pool is in.
            // 		`cognito-idp.us-east-2.amazonaws.com/${poolData.UserPoolId}`: result.getIdToken().getJwtToken(),
            // 	},
            // });

            //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
            // AWS.config.credentials.refresh(error => {
            // 	if (error) {
            // 		console.error(error);
            // 	} else {
            // 		// Instantiate aws sdk service objects now that the credentials have been updated.
            // 		// example: var s3 = new AWS.S3();
            // 		console.log('Successfully logged!');
            // 	}
            // });
        },

        newPasswordRequired: function (userAttributes, requiredAttributes) {
            // User was signed up by an admin and must provide new
            // password and required attributes, if any, to complete
            // authentication.

            // the api doesn't accept this field back
            delete userAttributes.email_verified;
            delete userAttributes.email;

            // store userAttributes on global variable
            // sessionUserAttributes = userAttributes;
        },

        onFailure: function (err) {
            alert(err.message || JSON.stringify(err));
        },
    });
}
export const logout = () => {
    let user = cognitoUserPool.getCurrentUser();
    if (user != null) {
        user.signOut()
    }
    const { CustomEvent } = window;
    const event = new CustomEvent('custom_logout');
    window.dispatchEvent(event);
}