import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { AXIOS_API_INSTANCE } from '../Constants';
import { Box, Checkbox, Chip, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';
import { send_authorized_request } from '../Constants/cognito';
import { useSnackbar } from 'notistack';
import { useTrackedAllLocations, useTrackedUser } from './UserState';
import ConfirmModal from '../Utils/ConfirmModal';

const tooltip_style = {
    tooltip: {
        sx: {
            bgcolor: 'common.black',
            '& .MuiTooltip-arrow': {
                color: 'common.black',
            },
        },
    },
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function UserModal({ user, open, closeModal, closeModalWithChanges, creatingNewUser }) {
    const { enqueueSnackbar } = useSnackbar();
    const [my_user,] = useTrackedUser();
    const [editing_user, setUser] = React.useState(user || {});
    const [locations,] = useTrackedAllLocations();
    const [deleteUserConfirmation, setDeleteUserConfirmation] = React.useState({
        open: false,
        text: '',
        callback: null
    })
    React.useEffect(() => {
        setUser(user);
    }, [user]);
    const permissions_list = ['permissions_user_admin',
        'permissions_items_admin',
        'permissions_client_config_admin',
        'permissions_refund_admin']
    console.log('RENDERING USER MODAL')

    const handleChange = (prop) => (event) => {
        if (permissions_list.includes(prop)) {
            setUser({ ...editing_user, [prop]: event.target.checked })
        } else {
            setUser({ ...editing_user, [prop]: event.target.value })
        }
    };

    const save = (event) => {
        console.log(editing_user)
        delete editing_user.date_created
        delete editing_user.date_updated
        if (creatingNewUser) {
            send_authorized_request(() => AXIOS_API_INSTANCE.post(`users`, editing_user).then(axios.spread(() => {
                enqueueSnackbar('Successfully created user', { variant: 'success' })
                closeModalWithChanges(true)
            })).catch((error) => {
                enqueueSnackbar(`Failed to create user <${error.response?.data?.message ?? "Unknown Error Occurred"}>`, { variant: 'error' })
            }))
        } else {
            send_authorized_request(() => AXIOS_API_INSTANCE.post(`users/${editing_user.id}`, editing_user).then(axios.spread(() => {
                enqueueSnackbar('Successfully updated user', { variant: 'success' })
                closeModalWithChanges(true)
            })).catch((error) => {
                enqueueSnackbar(`Failed to update user <${error.response?.data?.message ?? "Unknown Error Occurred"}>`, { variant: 'error' })
            }))
        }
    }

    const delete_user = (event) => {
        console.log('delete user')
        event.stopPropagation();
        const text = `Are you sure you want to delete user ${editing_user.email}?`
        setDeleteUserConfirmation({
            open: true,
            text: text,
            callback: (confirmation) => {
                if (confirmation) {
                    send_authorized_request(() =>
                        AXIOS_API_INSTANCE.delete(`users/${editing_user.id}`).then(() => {
                            enqueueSnackbar('Successfully deleted user', { variant: 'success' })
                            closeModalWithChanges(true)
                        })
                    )
                }
                setDeleteUserConfirmation({ text: text, open: false })
            }
        })
    }
    if (editing_user === null || editing_user === undefined) {
        return (<div></div>)
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    {creatingNewUser ? "Creating User" : "Modifying User"}
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container columns={{ xs: 4, sm: 12 }}>
                        <Grid item xs={4} sm={7}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Name"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        value={editing_user.name || ''}
                                        onChange={handleChange('name')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        disabled={!creatingNewUser}
                                        margin="dense"
                                        id="email"
                                        label="Email"
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                        value={editing_user.email || ''}
                                        onChange={handleChange('email')}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControl sx={{ marginTop: 1, width: '100%' }}>
                                        <InputLabel id="multiple-chip-locations-label">Assigned Locations</InputLabel>
                                        <Select
                                            labelId="multiple-chip-locations-label"
                                            id="multiple-chip-locations"
                                            label="Assigned Locations"
                                            multiple
                                            value={editing_user.location_ids || []}
                                            onChange={handleChange('location_ids')}
                                            input={<OutlinedInput id="select-multiple-chip" label="Assigned Locations" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={locations.find((loc) => loc.id === value)?.name} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {locations.map((loc) => (
                                                <MenuItem
                                                    key={loc.id}
                                                    value={loc.id}
                                                >
                                                    {loc.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={5} justifyContent={'flex-end'}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <span>Permissions</span>
                                </Grid>
                                {!creatingNewUser &&
                                    <Grid item xs={12}>
                                        <Tooltip componentsProps={tooltip_style} title="This user can do anything" arrow>
                                            <FormControlLabel control={<Checkbox checked={editing_user.root_admin || false} disabled />} label="Root admin" labelPlacement='start' sx={{ display: 'flex', 'justify-content': 'space-between' }} />
                                        </Tooltip>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Tooltip componentsProps={tooltip_style} title="This allows the user to create, modify, and delete users as well as alter their permissions" arrow>
                                        <FormControlLabel control={<Checkbox checked={editing_user.permissions_user_admin || false} onChange={handleChange('permissions_user_admin')} />} label="User admin" labelPlacement='start' sx={{ display: 'flex', 'justify-content': 'space-between' }} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip componentsProps={tooltip_style} title="This allows the user to create, modify, and delete items for sale" arrow>
                                        <FormControlLabel control={<Checkbox checked={editing_user.permissions_items_admin || false} onChange={handleChange('permissions_items_admin')} />} label="Inventory admin" labelPlacement='start' sx={{ display: 'flex', 'justify-content': 'space-between' }} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip componentsProps={tooltip_style} title="This allows the user to modify system-wide client configurations" arrow>
                                        <FormControlLabel control={<Checkbox checked={editing_user.permissions_client_config_admin || false} onChange={handleChange('permissions_client_config_admin')} />} label="Client Config admin" labelPlacement='start' sx={{ display: 'flex', 'justify-content': 'space-between' }} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip componentsProps={tooltip_style} title="This allows the user to execute refunds" arrow>
                                        <FormControlLabel control={<Checkbox checked={editing_user.permissions_refund_admin || false} onChange={handleChange('permissions_refund_admin')} />} label="Refund admin" labelPlacement='start' sx={{ display: 'flex', 'justify-content': 'space-between' }} />
                                    </Tooltip>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Tooltip componentsProps={tooltip_style} title="This allows the user to create orders" arrow>
                                        <FormControlLabel control={<Checkbox checked={my_user.permissions_checkout_admin || false} onChange={handleChange('permissions_checkout_admin')} />} label="Checkout admin" labelPlacement='start' sx={{ display: 'flex', 'justify-content': 'space-between' }} />
                                    </Tooltip>
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between" sx={{ padding: "0px 30px 10px 30px" }}>
                        <Grid item xs="auto">
                            {!creatingNewUser &&
                                <Button disabled={editing_user.root_admin || (!my_user.root_admin && !my_user.permissions_user_admin)} variant='outlined' color='error' onClick={delete_user} sx={{ mr: 1 }}>
                                    <DeleteIcon /> <Box sx={{ pl: 1 }}><span>Delete</span></Box>
                                </Button>
                            }
                        </Grid>
                        <Grid item xs="auto">
                            <Button variant="contained" onClick={save} disabled={!my_user.root_admin && !my_user.permissions_user_admin}>
                                {creatingNewUser ? "Create" : "Save"}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
                <ConfirmModal
                    {...deleteUserConfirmation}
                />
            </Dialog>
        </div>
    );
}