import { Backdrop, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';

// export default function LoadingBackdrop({ open, text, progress }) {
//     return (
//         <Backdrop
//             sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//             open={open}
//         >
//             <Box sx={{ width: '60%' }}>
//                 <Box sx={{ width: '100%', textAlign: 'center' }}>
//                     <h1>{text}</h1>
//                 </Box>
//                 <LinearProgress variant="determinate" value={progress} sx={{ height: 10, borderRadius: 1 }} />
//             </Box>
//         </Backdrop>
//     );
// }

export function LoadingBackdropIndeterminate({ open, text }) {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Box sx={{ width: '60%' }}>
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <h1>{text}</h1>
                </Box>
                <LinearProgress sx={{ height: 10, borderRadius: 1 }} />
            </Box>
        </Backdrop>
    );
}