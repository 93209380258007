import * as React from "react"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import { AXIOS_API_INSTANCE } from "../Constants"
import { send_authorized_request } from "../Constants/cognito"
import { Accordion, AccordionDetails, Badge, Fab, IconButton, useMediaQuery } from "@mui/material"
import { AccordionSummary } from "@mui/material";
import { Box } from "@mui/material"
import { Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { LoadingBackdropIndeterminate } from "./LoadingBackdrop"
import QuantityModal from "./QuantityModal"
import { update_cart, useTrackedCart } from "./CheckoutState"
import CartDrawer from "./CartDrawer"
import { moneyFormat, getItemPricing } from "../Utils"
import { useTrackedCustomer, useTrackedLocation } from "./UserState"
import { useNavigate } from "react-router-dom"
import ConfirmModal from "../Utils/ConfirmModal"
import { useTheme } from "@emotion/react"


export default function Order(props) {
    const [cart, setCart] = useTrackedCart();
    const [cartSize, setCartSize] = React.useState(0)
    const [location,] = useTrackedLocation();
    const [customer, setCustomer] = useTrackedCustomer();
    const [items, setItems] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [selectedItem, setSelectedItem] = React.useState(undefined)
    const [confirmation, setConfirmation] = React.useState({
        open: false,
        text: '',
        callback: null
    })
    const navigate = useNavigate();
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    if (cart.cart.length !== cartSize) {
        setCartSize(cart.cart.length)
    }
    if (location.id === 'all') {
        navigate('/Dashboard')
    }

    const restartOrder = () => {
        const text = `Are you sure you want to start over?`
        setConfirmation({
            open: true,
            text: text,
            callback: (confirmation) => {
                if (confirmation) {
                    setCart({ cart: [], open: false })
                    setCustomer(undefined)
                    navigate('/Dashboard')
                }
                setConfirmation({ text: text, open: false })
            }
        })
    }

    const getItems = () => {
        setIsLoading(true)
        send_authorized_request(() =>
            AXIOS_API_INSTANCE.get("items").then((items) => {
                console.log(items)
                setItems(items.data.sort((a, b) => {
                    if (a.quantity_of_measure !== b.quantity_of_measure) {
                        return (b.quantity_of_measure || '').localeCompare(a.quantity_of_measure)
                    } else {
                        return (a.name || '').localeCompare(b.name)
                    }
                }
                ))
                setIsLoading(false)
            }
            )
        )
    }
    React.useEffect(() => {
        getItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const types = items.map((x) => x.type).filter((value, index, array) => array.indexOf(value) === index).sort()
    const location_filtered_items = items.filter((item) => item.pricing[location.id])

    return (
        <React.Fragment>
            <Box width="100%">
                <LoadingBackdropIndeterminate
                    open={isLoading}
                    text="Loading Items"
                />
                {!customer &&
                    <h4>No Customer Selected</h4>
                }
                {customer && customer.id === -1 &&
                    <Box sx={{ paddingBottom: 2 }}>
                        <span><b><i>CACHING</i> Customer:</b> {customer.cust_name}</span>
                    </Box>
                }
                {customer && customer.id !== -1 &&
                    <Box sx={{ paddingBottom: 2 }}>
                        <span><b>Customer:</b> {customer.cust_name}</span>
                    </Box>
                }
                {types.map((type, index) =>
                    <Accordion key={type} defaultExpanded={index === 0}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="customeraccordion-content"
                            id="customeraccordion-header"
                        >
                            <Typography><b>{type}</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} columns={{
                                xs: 2,
                                sm: Math.min(3, location_filtered_items.filter((item) => item.type === type).length),
                                md: Math.min(4, location_filtered_items.filter((item) => item.type === type).length),
                                lg: Math.min(6, location_filtered_items.filter((item) => item.type === type).length),
                                xl: Math.min(6, location_filtered_items.filter((item) => item.type === type).length)
                            }} justifyContent={"center"}>
                                {location_filtered_items.filter((item) => item.type === type).map((item) =>
                                    <Grid key={item.id} item xs={1} justifyContent={"center"} alignItems={"center"}>
                                        <Box sx={{ textAlign: 'center', height: '100%' }}>
                                            <Button variant="contained" className={`item-quantityofmeasure-${item.quantity_of_measure}`} onClick={() => setSelectedItem(item)} sx={{ width: '90%', minHeight: '90px', height: '100%', maxWidth: '300px' }}>
                                                <Box fontSize={'16px'}>
                                                    <b>
                                                        <span>{item.name}</span>
                                                    </b>
                                                    <br />
                                                    <span style={{ fontWeight: 'normal' }}>${moneyFormat(getItemPricing(item, customer, location), false)} / {item.quantity_of_measure}</span>

                                                </Box>

                                            </Button>
                                        </Box>
                                    </Grid>
                                )
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
                <Grid container sx={{ width: '100%', mt: 3 }}>
                    <Grid item xs="auto" fontSize={'16px'}>
                        <Button variant="contained" autoFocus onClick={() => restartOrder()} sx={{ pt: 2, pb: 2, paddingLeft: '1', paddingRight: '1', marginRight: 1 }}>
                            <b>
                                Start Over
                            </b>
                        </Button>
                    </Grid>
                    <Grid item xs>

                    </Grid>
                </Grid>
                {!isLgUp && !cart.open &&
                    <Fab sx={{
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                    }}
                        color={'info'}
                        onClick={() => setCart({ ...cart, open: true })}
                    >
                        <IconButton color="inherit" disableRipple>
                            <Badge badgeContent={cartSize} color="error">
                                <AddShoppingCartIcon />
                            </Badge>
                        </IconButton>
                    </Fab>
                }
                <CartDrawer

                />
            </Box>
            <QuantityModal
                cart={cart}
                item={selectedItem}
                open={selectedItem !== undefined}
                closeModal={(result) => {
                    if (result) {
                        update_cart(cart, selectedItem, result, setCart)
                    }
                    setSelectedItem(undefined)
                }}
            />
            <ConfirmModal
                {...confirmation}
            />
        </React.Fragment>
    );
}
