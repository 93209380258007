import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PasswordChecklist from "react-password-checklist"
import { Checkbox, Container, Box } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { cognitoUserPool } from '../Constants/cognito'
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { useSnackbar } from 'notistack';

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
    opacity: 1,
    y: 0,
    transition: {
        duration: 0.6,
        ease: easing,
        delay: 0.16,
    },
};


const RootStyle = styled("div")({
    background: "rgb(249, 250, 251)",
    height: "100vh",
    display: "grid",
    placeItems: "center",
});

const ContentStyle = styled("div")({
    maxWidth: 480,
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "#fff",
});



export default function Login({ login_callback }) {
    const { enqueueSnackbar } = useSnackbar();
    const [checked, setChecked] = React.useState(true);
    const [firstLogin, setFirstLogin] = React.useState(false);
    const [isForgotPassword, setIsForgotPassword] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [verificationCode, setVerificationCode] = React.useState('');
    const [cognitoUser, setCognitoUser] = React.useState(undefined);

    var authenticationDetails = new AuthenticationDetails({
        Username: username.toLowerCase(),
        Password: password
    });
    var userData = {
        Username: username.toLowerCase(),
        Pool: cognitoUserPool,
    };

    const login = () => {
        handleLogin()
    }

    const handleLogin = () => {
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                console.log(result)
                login_callback()
            },

            totpRequired: () => {
                var verificationCode = prompt('Please input MFA verification code', '');
                cognitoUser.sendMFACode(
                    verificationCode,
                    {
                        onFailure: (err) => {
                            enqueueSnackbar(err.message || JSON.stringify(err), { variant: 'error' })
                        },
                        onSuccess: (result) => {
                            console.log(result)
                            login_callback()
                        },
                    },
                    "SOFTWARE_TOKEN_MFA"
                );
            },

            newPasswordRequired: function (userAttributes, requiredAttributes) {
                delete userAttributes.email_verified;
                delete userAttributes.email;
                setCognitoUser({ user: cognitoUser, attributes: userAttributes })
                setFirstLogin(true)
            },

            onFailure: function (err) {
                enqueueSnackbar(err.message || JSON.stringify(err), { variant: 'error' })
            },
        });
    }

    const forgotPassword = () => {
        if (username.trim().length !== 0) {
            var cognitoUser = new CognitoUser(userData);
            cognitoUser.forgotPassword({
                onSuccess: function (data) {
                    // successfully initiated reset password request
                    enqueueSnackbar("Verification Code Sent", { variant: 'success' })
                    setIsForgotPassword(true)
                },
                onFailure: function (err) {
                    enqueueSnackbar(err.message || JSON.stringify(err), { variant: 'error' })
                }
            });
        }
    }

    const confirmPassword = () => {
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess() {
                enqueueSnackbar('Password confirmed', { variant: 'success' })
                setIsForgotPassword(false)
            },
            onFailure(err) {
                enqueueSnackbar(err.message || JSON.stringify(err), { variant: 'error' })
            },
        });
    }

    const changePassword = () => {
        cognitoUser.user.completeNewPasswordChallenge(newPassword, cognitoUser.attributes, {
            onSuccess: result => {
                login_callback()
            },
            onFailure: function (err) {
                enqueueSnackbar(`Failed to update password: ${err.message}`, { variant: 'error' })
            },
        });
    };

    return (
        <RootStyle>
            <Container maxWidth="sm">
                <ContentStyle>
                    <Box
                        component={motion.div}
                        animate={animate}
                        initial={{ opacity: 0, y: 40 }}
                        sx={{ textAlign: 'center' }}
                    >
                        <h1>ParkingLot Checkout</h1>
                    </Box>
                </ContentStyle>
            </Container>
            <Container maxWidth="sm">
                <ContentStyle>

                    <Box
                        component={motion.div}
                        animate={{
                            transition: {
                                staggerChildren: 0.55,
                            },
                        }}
                    >
                        {firstLogin &&
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 3,
                                }}
                                component={motion.div}
                                initial={{ opacity: 0, y: 40 }}
                                animate={animate}
                            >
                                <TextField label="Username" value={username} disabled></TextField>
                                <TextField label="New Password" autocomplete="new-password" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} onKeyDown={(e) => { if (e.code === "Enter") { changePassword() } }} autoFocus ></TextField>
                                <PasswordChecklist
                                    rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
                                    minLength={8}
                                    value={newPassword}
                                    // valueAgain={passwordAgain}
                                    onChange={(isValid) => { }}
                                />
                                <Box
                                    component={motion.div}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={animate}
                                >
                                    <Button variant='contained' fullWidth onClick={changePassword}> Set New Password </Button>
                                </Box>
                            </Box>
                        }
                        {isForgotPassword &&
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 3,
                                }}
                                component={motion.div}
                                initial={{ opacity: 0, y: 40 }}
                                animate={animate}
                            >
                                <TextField label="Username" value={username} disabled></TextField>
                                <TextField label="Verification Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} autoFocus></TextField>
                                <TextField label="New Password" autocomplete="new-password" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} onKeyDown={(e) => { if (e.code === "Enter") { confirmPassword() } }} ></TextField>
                                <PasswordChecklist
                                    rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
                                    minLength={8}
                                    value={newPassword}
                                    // valueAgain={passwordAgain}
                                    onChange={(isValid) => { }}
                                />
                                <Box
                                    component={motion.div}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={animate}
                                >
                                    <Button variant='contained' fullWidth onClick={confirmPassword}> Set New Password </Button>
                                </Box>
                            </Box>
                        }
                        {!isForgotPassword && !firstLogin &&
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 3,
                                }}
                                component={motion.div}
                                initial={{ opacity: 0, y: 40 }}
                                animate={animate}
                            >
                                <TextField
                                    type="username"
                                    label="Username"
                                    value={username} onChange={(e) => setUsername(e.target.value)}
                                />
                                <TextField label="Password" type={'password'} value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => { if (e.code === "Enter") { login() } }} ></TextField>
                                <Box
                                    component={motion.div}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={animate}
                                >
                                    {/* TODO: THIS DOES NOTHING... */}
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={() => setChecked(!checked)}
                                                label={'Keep me logged in'}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        }
                                        label="Keep me logged in"
                                    />
                                    <Button variant='contained' fullWidth onClick={login}> Login </Button>
                                    <Box sx={{ textAlign: 'center', pt: 3 }}>
                                        <Button variant='text' onClick={forgotPassword}>Forgot Your Password?</Button>
                                    </Box>
                                </Box>
                            </Box>
                        }




                    </Box>

                </ContentStyle>
            </Container>
        </RootStyle>
    );
}